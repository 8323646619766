import {
    Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from "@mui/material";
import '../App.css';
import Select from 'react-select';
import React, { useCallback, useEffect, useRef, useState } from "react";
import TreeItem from '@mui/lab/TreeItem';
import { category } from "../repository/category";
import { Article, moveToAskForPrice } from "../repository/article";
import axios from "axios";
import { environment } from "../environment";
type Props = {
    categories: category[],
    onChange: (selected: string[]) => void;
    multiple?: boolean;
    saveMargens: () => void
    products: Article[];

}
type margen = {
    categegory: category,
    value: string
}

export default function ProductSelectBox(props: Props) {

    const [selectedOption, setSelectedOption] = useState<any>(undefined);
    const [filteredProducts, setFilteredProducts] = useState<Article[]>([]);
    const [selectedIDS, setSelectedIDS] = useState<number[]>([]);
    const [isAlertOpen, setIsAlertOpen] = useState(false);



    useEffect(() => {
        console.log(selectedOption)
        console.log(props.products.find(art => art.supplierNumber === '08512.445-0020'))

        if (selectedOption === null || selectedOption === undefined || selectedOption.length === 0) {
            setFilteredProducts([...props.products.filter(art => art.attr8 === null)]);
        } else {

            let calculate = props.products.filter(art => art.attr8 === null).filter((item) => {

                //console.log((item.category.some(r=> selectedOption.map((i:any) => i.value).indexOf(r) === -1)) && (item.subCategory.some(r=> selectedOption.map((i:any) => i.value).indexOf(r) === -1)), item.category, item.subCategory, selectedOption)
                let foundItem = selectedOption.find((option: any) => {
                    return item.category?.includes(option.value) || item.subCategory.includes(option.value)
                })

                return foundItem !== undefined
                // return (selectedOption.every((categoryID: any) => {
                //     return item.category.indexOf(categoryID.value) === -1 && item.subCategory.indexOf(categoryID.value) === -1
                // }));

                //return ((item.category.every(r=> selectedOption.map((i:any) => i.value).indexOf(r) === -1)) || (item.subCategory.every(r=> selectedOption.map((i:any) => i.value).indexOf(r) === -1)))
                //return item.category.includes(selectedOption.value) || item.subCategory.includes(selectedOption.value);
            });
            console.log('#-#-#-', calculate.length)
            setFilteredProducts([...calculate]);
        }

    }, [props.products, selectedOption]);

    useEffect(() => {

        console.log(filteredProducts.length);

    }, [filteredProducts])


    const handleSelectAll = useCallback((change: React.ChangeEvent<HTMLInputElement>) => {

        if (change.target.checked) {
            setSelectedIDS([...filteredProducts.map((item) => Number(item.incrID))])
        } else {

            filteredProducts.forEach((product) => {
                const index = selectedIDS.findIndex((item) => item === product.incrID);
                if (index !== -1) {
                    selectedIDS.splice(index, 1);
                }
            })
            setSelectedIDS([...selectedIDS]);

        }


    }, [filteredProducts, selectedIDS]);


    const isCheckedAll = useCallback(() => {

        let checked = true;

        filteredProducts.forEach((selected) => {
            if (selectedIDS.findIndex((item) => item === selected.incrID) === -1) {
                checked = false;
            }
        });

        if (selectedIDS.length === 0) {
            checked = false;
        }

        return checked;
    }, [filteredProducts, selectedIDS]);

    const saveAndApply = useCallback(async () => {

        const artList = props.products.filter((item) => {
            return selectedIDS.includes(Number(item.incrID));
        });

        const promiseList: Promise<any>[] = [];

        artList.forEach((item) => {
            promiseList.push(moveToAskForPrice(Number(item.incrID), item.category));
        })

        await Promise.all(promiseList);

        props.saveMargens()

    }, [props.products, selectedIDS]);


    const loadCategorySelection = useCallback(async () => {

        await axios.get(environment.ownBackendURL + 'savedCategories').then((response) => {

            setSelectedOption(response.data);

        }).catch((err) => {
            console.log("load err")
        })

    }, []);

    useEffect(() => {
        loadCategorySelection().then(undefined);
    }, [loadCategorySelection]);

    const saveCategorySelection = useCallback(async () => {

        await axios.put(environment.ownBackendURL + 'savedCategories', { content: selectedOption }).then((response) => {

            console.log("saved");

        }).catch((err) => {
            console.log("save err");
        });

    }, [selectedOption]);

    // console.log('#-#-#-', filteredProducts[0])
    return (
        <div style={{ height: '100%' }}>
            <>
                <Dialog
                    open={isAlertOpen}
                    keepMounted
                    onClose={() => setIsAlertOpen(false)}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"Möchten Sie fortfahren ?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Sie haben <b>{selectedIDS.length}</b> ausgewählt. Diese Artikel werden auf End of Life gesetzt.
                            Zudem wird der Pseudopreis gelöscht.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsAlertOpen(false)}>Abbrechen</Button>
                        <Button onClick={saveAndApply}>Bestätigen</Button>
                    </DialogActions>
                </Dialog>
            </>

            <Button onClick={() => saveCategorySelection()} color="secondary" style={{ position: 'absolute', right: 250, top: 12, }} variant="contained">Save Category Selection</Button>
            <Button onClick={() => setIsAlertOpen(true)} color="success" style={{ position: 'absolute', right: 12, top: 12, }} variant="contained">Speichern & anwenden</Button>
            <Select
                isClearable={true}
                value={selectedOption}
                isMulti={true}
                onChange={(change) => setSelectedOption(change)}
                options={props.categories.map((cat) => {
                    return { label: cat.name + '(' + cat.id + ')', value: cat.id }
                })}
            />

            <table className="customers">
                <tr>
                    <th><Checkbox checked={isCheckedAll()} onChange={handleSelectAll} /></th>
                    <th>Name</th>
                    <th>Herstellernummer</th>
                    <th>Preis EK</th>
                    <th> ID</th>
                    <th>Bestand</th>
                    <th>Preis</th>
                    <th>Subkategorie</th>
                    <th>Kategorie</th>
                </tr>

                {filteredProducts.map((product) => {

                    const filterCategoryNames = props.categories.filter((cat) => product.category.includes(cat.id));
                    const filterSubCategoryNames = props.categories.filter((cat) => product.subCategory.includes(cat.id));
                    return (
                        <tr key={product.incrID?.toString() + product.supplierNumber}>
                            <td><Checkbox onChange={(checked) => {
                                if (checked.target.checked) {
                                    selectedIDS.push(Number(product.incrID));
                                    setSelectedIDS([...selectedIDS]);
                                } else {
                                    const index = selectedIDS.findIndex((item) => item === product.incrID);
                                    if (index !== -1) {
                                        selectedIDS.splice(index, 1);
                                        setSelectedIDS([...selectedIDS]);
                                    }
                                }
                            }} checked={selectedIDS.includes(Number(product.incrID))} /></td>
                            <td>{product.name}</td>
                            <td>{product.supplierNumber}</td>
                            <td>{product.priceEK?.toFixed(2)}</td>
                            <td>{product.incrID}</td>
                            <td>{product.stock}</td>
                            <td>{product.price?.toFixed(2)}</td>
                            <td>{filterSubCategoryNames.map((item) => item.name + '(' + item.id + ')').join(', ')}</td>
                            <td>{filterCategoryNames.map((item) => item.name + '(' + item.id + ')').join(', ')}</td>
                        </tr>
                    )
                })}

            </table>

        </div >
    )

}
