import { Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { environment } from "../environment";
import { Article } from "../repository/article";

export default function UpdatePopUp(props: { matchingArticle: Article[]; close: () => void; safe: (ek: boolean, margen: boolean, promo: boolean, outlist: boolean) => void }) {
    const [loading, setLoading] = useState(true)
    const [ek, setEk] = useState(true)
    const [margen, setMargen] = useState(true)
    const [promo, setPromo] = useState(true)
    const [outlist, setOutlist] = useState(true);
    const [margenData, setMargenData] = useState([])
    const [promoData, setPromoData] = useState([])

    useEffect(() => {
        // axios.get(environment.ownBackendURL + 'margen').then(res => {
        //     setMargenData(res.data)
        // })
    }, [])

    return (
        <div style={{ position: 'fixed', top: 0, left: 0, backgroundColor: 'rgba(0,0,0, 0.4)', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ position: 'relative', height: '50%', width: '60%', backgroundColor: '#fff', display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexDirection: 'column' }}>
                <Button onClick={() => props.close()} color="error" style={{ position: 'absolute', width: 80, left: 10, top: 10 }} variant="contained">X</Button>
                <h2>Welche Daten sollen synchronisiert werden ? </h2>
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={promo} onChange={(event) => setPromo(event.target.checked)} />} label="Sonderpreise" />
                    <FormControlLabel control={<Checkbox checked={ek} onChange={(event) => setEk(event.target.checked)} />} label="Einkaufspreis und Bestand" />
                    <FormControlLabel control={<Checkbox checked={margen} onChange={(event) => setMargen(event.target.checked)} />} label="Margen" />
                    <FormControlLabel control={<Checkbox checked={outlist} onChange={(event) => setOutlist(event.target.checked)} />} label="Outlist articles that are in not in csv" />
                </FormGroup>
                <Button onClick={() => props.safe(ek, margen, promo, outlist)} color="success" style={{ position: 'relative', width: 120 }} variant="contained">Speichern</Button>
            </div>

        </div>
    )
}