export const IndisList = [
    {
        "id": 1683,
        "value": "Farbetikettendruck",
        "position": 20,
        "optionName": "Anwendungsbereich",
        "filterable": true
    },
    {
        "id": 2166,
        "value": "Thermotransfer-Druck (monochrom) auf Plastikkarten",
        "position": 21,
        "optionName": "Anwendungsbereich",
        "filterable": true
    },
    {
        "id": 2175,
        "value": "Thermotransfer-Druck (mehrfarbig) auf Plastikkarten",
        "position": 22,
        "optionName": "Anwendungsbereich",
        "filterable": true
    },
    {
        "id": 2573,
        "value": "Lager",
        "position": 29,
        "optionName": "Anwendungsbereich",
        "filterable": true
    },
    {
        "id": 2574,
        "value": "Logistik",
        "position": 30,
        "optionName": "Anwendungsbereich",
        "filterable": true
    },
    {
        "id": 2575,
        "value": "Transport",
        "position": 31,
        "optionName": "Anwendungsbereich",
        "filterable": true
    },
    {
        "id": 2576,
        "value": "Gesundheitswesen (Healthcare)",
        "position": 32,
        "optionName": "Anwendungsbereich",
        "filterable": true
    },
    {
        "id": 116,
        "value": "Tischladestation (Desktop-Cradle)",
        "position": 0,
        "optionName": "Anwendungsbereich",
        "filterable": true
    },
    {
        "id": 3008,
        "value": "Lebensmittelkennzeichnung",
        "position": 0,
        "optionName": "Anwendungsbereich",
        "filterable": true
    },
    {
        "id": 632,
        "value": "Stapler, Flurförderzeuge",
        "position": 27,
        "optionName": "Anwendungsbereich",
        "filterable": true
    },
    {
        "id": 3039,
        "value": "Lagerplatzkennzeichnung",
        "position": 0,
        "optionName": "Anwendungsbereich",
        "filterable": true
    },
    {
        "id": 806,
        "value": "Ladeplatz",
        "position": 3,
        "optionName": "Anwendungsbereich",
        "filterable": true
    },
    {
        "id": 3191,
        "value": "Anschluss MDE-Gerät an PC",
        "position": 0,
        "optionName": "Anwendungsbereich",
        "filterable": true
    },
    {
        "id": 921,
        "value": "Thermotransfer Etikettendruck",
        "position": 4,
        "optionName": "Anwendungsbereich",
        "filterable": true
    },
    {
        "id": 3244,
        "value": "Industrie",
        "position": 0,
        "optionName": "Anwendungsbereich",
        "filterable": true
    },
    {
        "id": 942,
        "value": "Handel (Retail)",
        "position": 6,
        "optionName": "Anwendungsbereich",
        "filterable": true
    },
    {
        "id": 3427,
        "value": "Kunden- / Bedienerdisplay",
        "position": 0,
        "optionName": "Anwendungsbereich",
        "filterable": true
    },
    {
        "id": 1082,
        "value": "Laden im Fahrzeug",
        "position": 10,
        "optionName": "Anwendungsbereich",
        "filterable": true
    },
    {
        "id": 3433,
        "value": "Gastronomie",
        "position": 0,
        "optionName": "Anwendungsbereich",
        "filterable": true
    },
    {
        "id": 1384,
        "value": "Tischladefunktion",
        "position": 14,
        "optionName": "Anwendungsbereich",
        "filterable": true
    },
    {
        "id": 4049,
        "value": "GHS-Etiketten",
        "position": 0,
        "optionName": "Anwendungsbereich",
        "filterable": true
    },
    {
        "id": 1401,
        "value": "Indoor",
        "position": 15,
        "optionName": "Anwendungsbereich",
        "filterable": true
    },
    {
        "id": 4050,
        "value": "Getränkeetiketten",
        "position": 0,
        "optionName": "Anwendungsbereich",
        "filterable": true
    },
    {
        "id": 1605,
        "value": "Thermodirekt Etikettendruck",
        "position": 16,
        "optionName": "Anwendungsbereich",
        "filterable": true
    },
    {
        "id": 4051,
        "value": "Lebensmitteletiketten",
        "position": 0,
        "optionName": "Anwendungsbereich",
        "filterable": true
    }
]


export const condiList = [
    {
        "id": 3050,
        "value": "neu",
        "position": 0,
        "optionName": "Kondition",
        "filterable": true
    },
    {
        "id": 3051,
        "value": "gebraucht, neuwertig",
        "position": 1,
        "optionName": "Kondition",
        "filterable": true
    },
    {
        "id": 3052,
        "value": "gebraucht, Gebrauchsspuren",
        "position": 2,
        "optionName": "Kondition",
        "filterable": true
    },
    {
        "id": 3053,
        "value": "abgekündigt, neuwertige Restposten",
        "position": 3,
        "optionName": "Kondition",
        "filterable": true
    },
    {
        "id": 3054,
        "value": "abgekündigt, Gebrauchtware auf Anfrage",
        "position": 4,
        "optionName": "Kondition",
        "filterable": true
    },
    {
        "id": 3055,
        "value": "abgekündigt, nicht mehr lieferbar",
        "position": 5,
        "optionName": "Kondition",
        "filterable": true
    }
]
