import React, {useCallback, useEffect, useState} from "react";
import TreeItem from "@mui/lab/TreeItem";
import {Button, TextField} from "@mui/material";
import {category, categoryInnerData, getAttributeList, getCategoryData, PropertiesList} from "../repository/category";
import CategorySelection from "./Series/CategorySelection";
import SeriesCreation from "./Series/SeriesCreation";

interface CategoryInterface {
    categories: category[];
}

export default function CategoryAttributes(props: CategoryInterface){

    const {categories} = props;
    const [serieName, setSerieName] = useState("");
    const [selectedData, setSelectedData] = useState<null | categoryInnerData>(null);
    const [attributeList, setAttributeList] = useState<PropertiesList[]>([]);

    const [selectedAtrribute, setSelectedAtrribute] = useState<PropertiesList>();

    const [attributeValues, setAttributeValues] = useState<{[key: number]: string}>({});

    const getData = useCallback(async (selectedCategoryId: number) => {

        const data = await getCategoryData(selectedCategoryId);

        setSelectedData(data);

    }, []);

    const getAttribues = useCallback(async () => {
        const attr = await getAttributeList();
        setAttributeList(attr as PropertiesList[]);
    }, []);

    useEffect(() => {
        getAttribues();
    }, []);

    console.log(attributeValues);

    const findSubsTree = useCallback((categoryID: number, comingFromParent?: boolean) => {
        const parent = categories.find((parentCat) => parentCat.id === categoryID);
        const subs = categories.filter((category) => category.parentId === categoryID);
        if (parent === undefined) {
            return;
        }

        if (comingFromParent) {
            return (
                <>
                    {subs.map((category) => (
                        <optgroup key={category.id} label={category.name}>
                            {findSubsTree(category.id, true)}
                        </optgroup>
                    ))}
                </>
            );
        }

        if(parent.childrenCount === 0){
            return  <option value={parent.id}>{parent.name}</option>

        }

        return (
            <optgroup key={parent.id} label={parent.name}>
                {subs.map((category) => (
                    <React.Fragment key={category.id}>
                        <option value={category.id}>{category.name}</option>
                        {findSubsTree(category.id, true)}
                    </React.Fragment>
                ))}
            </optgroup>
        );
    }, [categories]);

    return (
        <div style={{height: '100%', marginTop:50}}>

            <SeriesCreation />
            {/*<div style={{marginBottom: 10}}>*/}
            {/*    <div>Name Of The Series</div>*/}
            {/*    <TextField label="Serie Name" onChange={(event) => setSerieName(event.target.value)} value={serieName} />*/}
            {/*</div>*/}

            {/*<div style={{marginBottom: 10}}>*/}
            {/*    <div>Category Zone</div>*/}
            {/*    <CategorySelection categories={categories} multiple={true}/>*/}
            {/*</div>*/}

            {/*<div style={{marginBottom: 10}}>*/}
            {/*    <div>Attribute List</div>*/}
            {/*    <select onChange={(event) => {*/}
            {/*        if (event.target.value !== "0") {*/}
            {/*            setSelectedAtrribute(attributeList.find((i) => i.id === Number(event.target.value)));*/}
            {/*            setAttributeValues({});*/}
            {/*        }*/}
            {/*    }}>*/}
            {/*        <option value="0"></option>*/}
            {/*        {attributeList.map((i) => {*/}
            {/*            return (<option value={i.id}>{i.name}</option>)*/}
            {/*        })}*/}
            {/*    </select>*/}
            {/*</div>*/}

            {/*{selectedAtrribute && (*/}
            {/*    <div style={{marginBottom: 10}}>*/}
            {/*        {selectedAtrribute.options.map((option) => {*/}
            {/*            return (*/}
            {/*                <TextField style={{marginRight: 5, marginBottom: 10}} key={option.id + "-" + selectedAtrribute.id} label={option.name}*/}
            {/*                           placeholder={option.name} value={attributeValues[option.id]}*/}
            {/*                           onChange={(event) => {*/}
            {/*                               attributeValues[option.id] = event.target.value;*/}
            {/*                               setAttributeValues({...attributeValues});*/}
            {/*                           }}/>*/}
            {/*            )*/}
            {/*        })}*/}


            {/*    </div>*/}
            {/*)}*/}


            {/*<Button onClick={() => {}} variant="outlined">Save Serie</Button>*/}

            {/*/!*<select onChange={(event) => {*!/*/}
            {/*/!*    getData(Number(event.target.value));*!/*/}
            {/*/!*}}>*!/*/}
            {/*/!*    {categories.map(category => findSubsTree(category.id))}*!/*/}
            {/*/!*</select>*!/*/}

            {/*/!*<div>*!/*/}
            {/*/!*    {selectedData && (*!/*/}
            {/*/!*        <div>*!/*/}
            {/*/!*            Selected Attribute Data: {JSON.stringify(selectedData)}*!/*/}
            {/*/!*        </div>*!/*/}
            {/*/!*    )}*!/*/}
            {/*/!*</div>*!/*/}
        </div>
    );
}
