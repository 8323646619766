import {Autocomplete, Box, FormControlLabel, Switch, TextField} from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {category, ManufacturersInterface, TaxesInterface} from "../../repository/category";
import {extraFieldList} from "./AdditionalData/AttributeList";
import {CheckBox} from "@mui/icons-material";
import SelectBoxWithFillable from "./AdditionalData/SelectBoxWithFillable";
import React, {useEffect} from "react";
import {condiList, IndisList} from "./AdditionalData/IndisList";
import {Product} from "./TabList";


interface ProductDetailsInterface {
    manufacturers: ManufacturersInterface[];
    taxes: TaxesInterface[];
    categories: category[];

    setProductData: React.Dispatch<React.SetStateAction<Product>>
    productData: Product
}

export default function ProductDetails(props: ProductDetailsInterface){

    const {productData, setProductData} = props;

    const [builtCategories, setBuiltCategories] = React.useState<{id: number, label: string}[]>([]);

    useEffect(() => {


        const categoryMap: any = {};
        props.categories.forEach(category => {
            categoryMap[category.id] = category;
        });

        const buildLabel = (category: category): any =>  {
            if (category.parentId) {
                const parent = categoryMap[category.parentId];
                return buildLabel(parent) + ' > ' + category.name;
            }
            return category.name;
        }

        const resultArray = props.categories.map(category => {
            return {
                id: category.id,
                label: buildLabel(category)
            };
        });

        setBuiltCategories(resultArray.map((a) => {
            return {...a, label: a.label.replace("Root >", "")}
        }));

    }, [props.categories]);



    return (
        <Box>
            <h3 style={{marginBottom: 10}}>General Information</h3>
            <Box>
                <FormControl sx={{m: 1, minWidth: 120}} size="small">
                    <TextField
                        size={"small"}
                        id="namelabelid2"
                        placeholder={"Item Name"}
                        label="Item Name"
                        value={productData.name}
                        onChange={(event) => {
                            setProductData((prev) => {
                                return {...prev, name: event.target.value}
                            });
                        }}
                    />
                </FormControl>
                <FormControl sx={{m: 1, minWidth: 120}} size="small">
                    <TextField
                        size={"small"}
                        id="numberitem"
                        placeholder={"Item Number"}
                        label="Number"
                        value={productData.mainDetail.number}
                        onChange={(event) => {
                            setProductData((prev) => {
                                return {...prev, mainDetail: {...prev.mainDetail, number: event.target.value}}
                            });
                        }}
                    />
                </FormControl>
            </Box>

            <FormControl sx={{m: 1, minWidth: 120}} size="small">
                <InputLabel id="manufakturerinputselection">Manufacturer</InputLabel>
                <Select
                    labelId="manufakturerinputselection"
                    id="manufakturerinputselection2"
                    label="Age"
                    value={productData.supplierId}
                    onChange={(event) => {
                        setProductData((prev) => {
                            return {...prev, supplierId: event.target.value}
                        });
                    }}
                >

                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {props.manufacturers.map((i) => (
                        <MenuItem value={i.id}>{i.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl sx={{m: 1, minWidth: 120}} size="small">
                <InputLabel id="taxeinputselection">Tax</InputLabel>
                <Select
                    labelId="taxeinputselection"
                    id="taxeinputselection2"
                    label="Tax"
                    value={productData.taxId}
                    onChange={(event) => {
                        setProductData((prev) => {
                            return {...prev, taxId: Number(event.target.value)}
                        });
                    }}
                >

                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {props.taxes.map((i) => (
                        <MenuItem value={i.id}>{i.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl sx={{m: 1, minWidth: 120}} size="small">
                <TextField
                    size={"small"}
                    id="purchespricTs"
                    placeholder={"Purchase Price"}
                    label="Purchase Price"
                    value={productData.mainDetail.purchasePrice}
                    onChange={(event) => {
                        setProductData((prev) => {
                            return {...prev, mainDetail: {...prev.mainDetail, purchasePrice: event.target.value}}
                        });
                    }}
                />
            </FormControl>

            <FormControl sx={{m: 1, minWidth: 120}} size="small">
                <TextField
                    size={"small"}
                    id="listPriceTs"

                    placeholder={"List Price"}
                    label="List Price"
                    value={productData.mainDetail.prices[0].price}
                    onChange={(event) => {

                        setProductData((prev) => {
                            const price = prev.mainDetail.prices[0];
                            price.price = (Number(event.target.value));
                            return {...prev, mainDetail: {...prev.mainDetail, prices: [price]}}
                        });
                    }}
                />
            </FormControl>


            <Box>
                <h3>Item Description</h3>
                <FormControl sx={{m: 1, minWidth: '50%'}} size="small">
                    <TextField
                        rows={4}
                        multiline={true}
                        size={"small"}
                        id="listPriceTs"

                        placeholder={"Description"}
                        label="Description"
                        value={productData.mainDetail.descriptionLong}
                        onChange={(event) => {
                            setProductData((prev) => {
                                return {...prev, mainDetail: {...prev.mainDetail, descriptionLong: event.target.value}}
                            });
                        }}
                    />
                </FormControl>
            </Box>


            <Box>
                <h3>Meta Fields</h3>

                <Box>
                    <FormControl sx={{m: 1, minWidth: '50%'}} size="small">
                        <TextField
                            size={"small"}
                            id="listPriceTs"
                            placeholder={"Title"}
                            label="Title"
                            value={productData.metaTitle}
                            onChange={(event) => {
                                setProductData((prev) => {
                                    return {...prev, metaTitle: event.target.value}
                                });
                            }}
                        />
                    </FormControl>

                </Box>

                <FormControl sx={{m: 1, minWidth: '50%'}} size="small">
                    <TextField
                        rows={4}
                        multiline={true}
                        size={"small"}
                        id="listPriceTs"

                        placeholder={"Description"}
                        label="Description"
                        value={productData.description}
                        onChange={(event) => {
                            setProductData((prev) => {
                                return {...prev, description: event.target.value}
                            });
                        }}
                    />
                </FormControl>
                <Box>
                    <FormControl sx={{m: 1, minWidth: '50%'}} size="small">
                        <TextField
                            rows={4}
                            multiline={true}
                            size={"small"}
                            id="listPriceTs"

                            placeholder={"Keywords"}
                            label="Keywords"
                            value={productData.keywords}
                            onChange={(event) => {
                                setProductData((prev) => {
                                    return {...prev, keywords: event.target.value}
                                });
                            }}
                        />
                    </FormControl>

                </Box>

            </Box>
            <h3>Settings</h3>
            <Box style={{width: '50%', marginLeft: 'auto', marginRight: 'auto', display: "flex", justifyContent: "center", alignItems: "center"}}>


                <Box style={{width: '100%'}}>
                    <FormControl sx={{m: 1, minWidth: '50%'}} size="small">
                        <TextField
                            multiline={false}
                            size={"small"}
                            id="listPriceTs"
                            placeholder={"Delivery Time"}
                            label="Delivery Time"
                            value={props.productData.mainDetail.shippingTime}
                            onChange={(event) => {
                                setProductData((prev) => {

                                    return {...prev, mainDetail: {...prev.mainDetail, shippingTime: event.target.value}}

                                })
                            }}
                        />
                    </FormControl>

                    <FormControl sx={{m: 1, minWidth: '50%'}} size="small">
                        <TextField
                            multiline={false}
                            size={"small"}
                            id="listPriceTs"

                            placeholder={"Stock"}
                            label="Stock"
                            value={props.productData.mainDetail.inStock}
                            onChange={(event) => {
                                setProductData((prev) => {

                                    return {...prev, mainDetail: {...prev.mainDetail, inStock: Number(event.target.value)}}

                                })
                            }}
                        />
                    </FormControl>


                    <FormControl sx={{m: 1, minWidth: '50%'}} size="small">
                        <TextField
                            multiline={false}
                            size={"small"}
                            id="listPriceTs"

                            placeholder={"Date Of Creation"}
                            label="Date Of Creation"
                            value={props.productData.added}
                            onChange={(event) => {
                                setProductData((prev) => {

                                    return {...prev, added: event.target.value}

                                })
                            }}
                        />
                    </FormControl>


                    <FormControl sx={{m: 1, minWidth: '50%'}} size="small">
                        <TextField
                            multiline={false}
                            size={"small"}
                            id="listPriceTs"

                            placeholder={"Pseudo Sales"}
                            label="Pseudo Sales"
                            value={props.productData.pseudoSales}
                            onChange={(event) => {
                                setProductData((prev) => {

                                    return {...prev, pseudoSales: Number(event.target.value)}

                                })
                            }}
                        />
                    </FormControl>


                    <FormControl sx={{m: 1, minWidth: '50%'}} size="small">
                        <TextField
                            multiline={false}
                            size={"small"}
                            id="listPriceTs"

                            placeholder={"Minimum Order"}
                            label="Minimum Order"
                            value={props.productData.mainDetail.minPurchase}
                            onChange={(event) => {
                                setProductData((prev) => {
                                    return {...prev, mainDetail: {...prev.mainDetail, minPurchase: Number(event.target.value)}}
                                })
                            }}
                        />
                    </FormControl>



                    <FormControl sx={{m: 1, minWidth: '50%'}} size="small">
                        <TextField
                            multiline={false}
                            size={"small"}
                            id="listPriceTs"

                            placeholder={"Maximum Order Order"}
                            label="Maximum Order"
                            value={props.productData.mainDetail.maxPurchase}
                            onChange={(event) => {
                                setProductData((prev) => {
                                    return {...prev, mainDetail: {...prev.mainDetail, maxPurchase: Number(event.target.value)}}
                                })
                            }}
                        />
                    </FormControl>



                </Box>
                <Box style={{width: '100%'}}>

                    <FormControl sx={{m: 1, minWidth: '50%'}} size="small">
                        <TextField
                            multiline={false}
                            size={"small"}
                            id="listPriceTs"

                            placeholder={"Manufacturer Number"}
                            label="Manufacturer Number"
                            value={props.productData.mainDetail.supplierNumber}
                            onChange={(event) => {
                                setProductData((prev) => {
                                    return {...prev, mainDetail: {...prev.mainDetail, supplierNumber: (event.target.value)}}
                                })
                            }}
                        />
                    </FormControl>



                    <Box>
                        <FormControl sx={{m: 1, minWidth: '50%'}} size="small">
                            <FormControlLabel control={<Switch  value={props.productData.mainDetail.shippingFree}
                                                                onChange={(event) => {
                                                                    setProductData((prev) => {
                                                                        return {...prev, mainDetail: {...prev.mainDetail, shippingFree: !!(event.target.value)}}
                                                                    })
                                                                }} />} label={"Free Shipping"}/>
                        </FormControl>
                    </Box>

                    <Box>
                        <FormControl sx={{m: 1, minWidth: '50%'}} size="small">
                            <FormControlLabel control={<Switch  value={props.productData.highlight}
                                                                onChange={(event) => {
                                                                    setProductData((prev) => {
                                                                        return {...prev, highlight: !!(event.target.value)}
                                                                    })
                                                                }}/>} label={"Highlight Item"}/>
                        </FormControl>
                    </Box>


                    <FormControl sx={{m: 1, minWidth: '50%'}} size="small">
                        <TextField
                            multiline={false}
                            size={"small"}
                            id="listPriceTs"
                            placeholder={"Weight (bw)"}
                            label="Weight (bw)"
                            value={props.productData.mainDetail.weight}
                            onChange={(event) => {
                                setProductData((prev) => {
                                    return {...prev, mainDetail: {...prev.mainDetail, weight: (event.target.value)}}
                                })
                            }}
                        />
                    </FormControl>


                    <FormControl sx={{m: 1, minWidth: '50%'}} size="small">
                        <TextField
                            multiline={false}
                            size={"small"}
                            id="listPriceTs"
                            placeholder={"EAN"}
                            label="EAN"
                            value={props.productData.mainDetail.ean}
                            onChange={(event) => {
                                setProductData((prev) => {
                                    return {...prev, mainDetail: {...prev.mainDetail, ean: (event.target.value)}}
                                })
                            }}
                        />
                    </FormControl>


                    <FormControl sx={{m: 1, minWidth: '50%'}} size="small">
                        <TextField
                            multiline={false}
                            size={"small"}
                            id="listPriceTs"

                            placeholder={"Width"}
                            label="Width"
                            value={props.productData.mainDetail.width}
                            onChange={(event) => {
                                setProductData((prev) => {
                                    return {...prev, mainDetail: {...prev.mainDetail, width: (event.target.value)}}
                                })
                            }}
                        />
                    </FormControl>


                    <FormControl sx={{m: 1, minWidth: '50%'}} size="small">
                        <TextField
                            multiline={false}
                            size={"small"}
                            id="listPriceTs"

                            placeholder={"Height"}
                            label="Height"
                            value={props.productData.mainDetail.height}
                            onChange={(event) => {
                                setProductData((prev) => {
                                    return {...prev, mainDetail: {...prev.mainDetail, height: (event.target.value)}}
                                })
                            }}
                        />
                    </FormControl>


                    <FormControl sx={{m: 1, minWidth: '50%'}} size="small">
                        <TextField
                            multiline={false}
                            size={"small"}
                            id="listPriceTs"
                            placeholder={"Length"}
                            label="Length"
                            value={props.productData.mainDetail.len}
                            onChange={(event) => {
                                setProductData((prev) => {
                                    return {...prev, mainDetail: {...prev.mainDetail, len: (event.target.value)}}
                                })
                            }}
                        />
                    </FormControl>

                </Box>

            </Box>


            <Box>
                <h3>Free Text Fields</h3>
                {extraFieldList.filter((field) => field.displayInBackend).map((field) => {

                    if (field.columnType === "boolean") {

                        return (
                            <Box>
                                <FormControl sx={{m: 1, minWidth: '50%'}} size="small">
                                    <FormControlLabel control={<Switch value={productData.mainDetail.attribute[field.columnName] ?? ''}
                                                                       onChange={(_, value) => {
                                                                           setProductData((prev) => {

                                                                               productData.mainDetail.attribute[field.columnName] = value;

                                                                               return {...prev, mainDetail: {...prev.mainDetail, attribute: {...prev.mainDetail.attribute}}}
                                                                           });
                                                                       }} />} label={field.label}/>
                                </FormControl>
                            </Box>
                        )

                    }

                    if (field.columnName === "attr20") {

                        return (
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={builtCategories}
                                value={productData.mainDetail.attribute[field.columnName] ?? ''}
                                onChange={(_, value) => {
                                    setProductData((prev) => {

                                        productData.mainDetail.attribute[field.columnName] = value;

                                        return {...prev, mainDetail: {...prev.mainDetail, attribute: {...prev.mainDetail.attribute}}}
                                    });
                                }}
                                style={{
                                    marginBottom: 10,
                                    minWidth: '50%',
                                    width: "50%",
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    alignSelf: 'center',
                                    justifySelf: 'center'
                                }}
                                renderInput={(params) => <TextField {...params} label={field.label}/>}
                            />
                        )

                    }

                    if (field.columnName === 'article_condition') {

                        return (
                            <Autocomplete
                                disablePortal
                                multiple={false}
                                options={condiList.map((a) => {
                                    return {
                                        id: a.id,
                                        label: a.optionName + " > " + a.value
                                    }
                                })}
                                value={productData.mainDetail.attribute[field.columnName] ?? ''}
                                onChange={(_, value) => {
                                    setProductData((prev) => {

                                        productData.mainDetail.attribute[field.columnName] = value;

                                        return {...prev, mainDetail: {...prev.mainDetail, attribute: {...prev.mainDetail.attribute}}}
                                    });
                                }}
                                style={{
                                    marginBottom: 10,
                                    minWidth: '50%',
                                    width: "50%",
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    alignSelf: 'center',
                                    justifySelf: 'center'
                                }}
                                renderInput={(params) => <TextField {...params} label={field.label}/>}
                            />
                        )

                    }

                    if (field.columnName === "industries") {

                        return (
                            <Autocomplete
                                disablePortal
                                multiple
                                options={IndisList.map((a) => {
                                    return {
                                        id: a.id,
                                        label: a.optionName + " > " + a.value
                                    }
                                })}
                                value={productData.mainDetail.attribute[field.columnName] ?? []}
                                onChange={(_, value) => {
                                    setProductData((prev) => {

                                        productData.mainDetail.attribute[field.columnName] = value;

                                        return {...prev, mainDetail: {...prev.mainDetail, attribute: {...prev.mainDetail.attribute}}}
                                    });
                                }}
                                style={{
                                    marginBottom: 10,
                                    minWidth: '50%',
                                    width: "50%",
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    alignSelf: 'center',
                                    justifySelf: 'center'
                                }}
                                renderInput={(params) => <TextField {...params} label={field.label}/>}
                            />
                        )

                    }

                    return (
                        <Box>
                            <FormControl sx={{m: 1, minWidth: '50%'}} size="small">
                                <TextField
                                    defaultValue={field.defaultValue}
                                    type={field.columnType === 'integer' ? 'number' : 'text'}
                                    rows={field.columnType === 'text' ? 4 : 1}
                                    multiline={field.columnType === 'text'}
                                    size={"small"}
                                    id="listPriceTs"
                                    placeholder={field.label ?? ""}
                                    label={field.label}
                                    value={productData.mainDetail.attribute[field.columnName] ?? ''}
                                    onChange={(event) => {
                                        setProductData((prev) => {

                                            productData.mainDetail.attribute[field.columnName] = event.target.value;

                                            return {...prev, mainDetail: {...prev.mainDetail, attribute: {...prev.mainDetail.attribute}}}
                                        });
                                    }}


                                />
                            </FormControl>
                        </Box>
                    )


                })}
            </Box>

        </Box>
    )

}
