import {Box, TextField} from "@mui/material";
import React from "react";
import {PropertiesList} from "../../repository/category";
import SelectBoxWithFillable from "./AdditionalData/SelectBoxWithFillable";
import {Product} from "./TabList";

interface AttributesSelectionInterface {
    setSelectedAttribute: React.Dispatch<React.SetStateAction<PropertiesList | undefined>>
    attributeList: PropertiesList[];
    selectedAttribute: PropertiesList | undefined;
    attributeValues: {[key: number]: string};
    setAttributeValues: React.Dispatch<React.SetStateAction<{[key: number]: string}>>;
    articleData: any[];


    setProductData: React.Dispatch<React.SetStateAction<Product>>
    productData: Product


}

export default function AttributesSelection(props: AttributesSelectionInterface){

    const {attributeValues, setAttributeValues, attributeList} = props;


    return (
        <>
            <div style={{marginBottom: 10}}>
                <div>Attribute List</div>
                <select value={props.productData.filterGroupId} onChange={(event) => {
                    if (event.target.value !== "0") {

                        props.setProductData((prev) => {
                            return {
                                ...prev, filterGroupId: attributeList.find((i) => i.id === Number(event.target.value))?.id ?? 0
                            }
                        });
                    }
                }}>
                    <option value="0"></option>
                    {attributeList.map((i) => {
                        return (<option value={i.id}>{i.name}</option>)
                    })}
                </select>
            </div>

            {attributeList.find((i) => i.id === props.productData.filterGroupId) && (
                <Box style={{marginBottom: 10, display: "flex", alignItems: 'center', justifyContent: "center", flexWrap: 'wrap', width: '100%'}}>
                    {attributeList.find((i) => i.id === props.productData.filterGroupId)?.options.map((option) => {
                        const dataList: any[] = [];
                        props.articleData.map((i) => i.propertyValues ?? []).forEach((i) => i.forEach((a: any) => dataList.push(a)));
                        const filterValueItemWithItsId = dataList.filter((i) => i.optionId === option.id).map((i) => i.value);

                        const uniqueList = [...new Set(filterValueItemWithItsId)];


                        const filterableList = uniqueList.map((i) => {
                            return {
                                title: i
                            }
                        });
                        return (

                                <SelectBoxWithFillable data={filterableList}  style={{marginRight: 5, marginBottom: 10, minWidth: 300}} value={props.productData.propertyValues.find((i) => i.option.name === option.name)?.value ?? ''} key={option.id + "-" + attributeList.find((i) => i.id === props.productData.filterGroupId)?.id} label={option.name} onChange={(event) => {

                                    props.setProductData((prev) => {

                                        const findOption = prev.propertyValues.findIndex((i) => i.option.name === option.name);

                                        if(findOption !== -1){
                                            prev.propertyValues[findOption].value = event;
                                        }else{
                                            prev.propertyValues.push({
                                                value: event,
                                                option: {
                                                    name: option.name
                                                }
                                            })
                                        }
                                        return {...prev, propertyValues: [...prev.propertyValues]}
                                    })
                                }} />


                        )
                    })}


                </Box>
            )}
        </>
    )

}
