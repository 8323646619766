import TabList from "./TabList";
import {useCallback, useEffect, useState} from "react";
import {
    category,
    getAttributeList,
    getCategories,
    getManufacturers, getSeries, getTaxDetails,
    ManufacturersInterface,
    PropertiesList, TaxesInterface
} from "../../repository/category";
import {Button} from "@mui/material";

export default function (){

    const [categories, setCategories] = useState<category[]>([])
    const [attributeList, setAttributeList] = useState<PropertiesList[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<number[]>([])

    const [selectedAttribute, setSelectedAttribute] = useState<PropertiesList>();
    const [creationOpen, setCreationOpen] = useState(false);
    const [attributeValues, setAttributeValues] = useState<{[key: number]: string}>({});
    const [type, setType] = useState<'create' | 'post'>('create');
    const [selectedProduct, setSelectedProduct] = useState<any>(undefined);
    const [manufacturers, setManufacturers] = useState<ManufacturersInterface[]>([]);
    const [series, setSeries] = useState([]);
    const [taxes, setTaxes] = useState<TaxesInterface[]>([
        {
            "id": 1,
            "tax": "19.00",
            "name": "19%"
        },
        {
            "id": 4,
            "tax": "7.00",
            "name": "7%"
        },
        {
            "id": 5,
            "tax": "0.00",
            "name": "steuerfrei"
        },
        {
            "id": 6,
            "tax": "12.00",
            "name": "12%"
        }
    ]);


    const getAttribues = useCallback(async () => {
        const attr = await getAttributeList();
        setAttributeList(attr as PropertiesList[]);
    }, []);

    useEffect(() => {
        getAttribues();
        getSeries().then((serie) => {
            setSeries(serie as any);
        })
        getTaxDetails();
        getManufacturers().then((manufacturersList) => {
            setManufacturers(manufacturersList);
        })
        getCategories().then(cats => {
            setCategories(cats)
        });
    }, []);


    return (
        <div>

            {creationOpen ? (

                <TabList
                    productData={selectedProduct}
                    type={type}
                    selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}
                    categories={categories}

                    attributeList={attributeList}
                    selectedAttribute={selectedAttribute}
                    setSelectedAttribute={setSelectedAttribute}
                    attributeValues={attributeValues}
                    setAttributeValues={setAttributeValues}
                    manufacturers={manufacturers}
                    taxes={taxes}
                />


            ): (
                <div>
                    <h3>Series List</h3>
                    <br />
                    <Button onClick={() => {
                        setCreationOpen(true);
                        setType('create');
                    }} variant={"contained"}>Create New Serie</Button>
                    <div>
                        <ul style={{listStyle: 'none', margin: 0, padding: 0, marginTop: 20}}>
                            {series.map((i: any) => {
                                const data = JSON.parse(i.seriedata);

                                return (
                                    <Button onClick={() => {
                                        setCreationOpen(true);
                                        setType('post');
                                        setSelectedProduct(data);
                                    }} variant={"outlined"}>{data.name} - Serie </Button>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            )}


        </div>
    )

}
