export const extraFieldList = [
    {
        "id": 20,
        "label": "",
        "helpText": "",
        "supportText": "",
        "position": 0,
        "displayInBackend": false,
        "readonly": false,
        "custom": false,
        "configured": true,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "attr4",
        "defaultValue": null,
        "identifier": false,
        "core": true,
        "columnType": "text",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "TEXT",
        "entity": null,
        "arrayStore": ""
    },
    {
        "id": 73,
        "label": "",
        "helpText": "",
        "supportText": "",
        "position": 0,
        "displayInBackend": false,
        "readonly": false,
        "custom": false,
        "configured": true,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "attr6",
        "defaultValue": "NULL",
        "identifier": false,
        "core": true,
        "columnType": "text",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "TEXT",
        "entity": null,
        "arrayStore": ""
    },
    {
        "id": 32,
        "label": "Produktfamilie",
        "helpText": "",
        "supportText": "Zeit ein Link zur Produktfamilie an",
        "position": 0,
        "displayInBackend": true,
        "readonly": false,
        "custom": false,
        "configured": true,
        "translatable": true,
        "tableName": "s_articles_attributes",
        "columnName": "attr20",
        "defaultValue": "NULL",
        "identifier": false,
        "core": true,
        "columnType": "single_selection",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "TEXT",
        "entity": "Shopware\\Models\\Category\\Category",
        "arrayStore": ""
    },
    {
        "id": 58,
        "label": null,
        "helpText": null,
        "supportText": null,
        "position": 0,
        "displayInBackend": false,
        "readonly": false,
        "custom": false,
        "configured": true,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "is_swag_custom_products",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "boolean",
        "elasticSearchType": {
            "type": "boolean"
        },
        "dbalType": "integer",
        "sqlType": "INT(1)",
        "entity": null,
        "arrayStore": null
    },
    {
        "id": 35,
        "label": "Produktbezeichnung Lang",
        "helpText": null,
        "supportText": null,
        "position": 0,
        "displayInBackend": true,
        "readonly": false,
        "custom": false,
        "configured": true,
        "translatable": true,
        "tableName": "s_articles_attributes",
        "columnName": "long_name",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "text",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "TEXT",
        "entity": null,
        "arrayStore": null
    },
    {
        "id": 80,
        "label": "Einkaufswelt auswählen",
        "helpText": null,
        "supportText": null,
        "position": 0,
        "displayInBackend": true,
        "readonly": false,
        "custom": false,
        "configured": true,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "pixel_emotion_id",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "integer",
        "elasticSearchType": {
            "type": "long"
        },
        "dbalType": "integer",
        "sqlType": "INT(11)",
        "entity": null,
        "arrayStore": null
    },
    {
        "id": 83,
        "label": "Einkaufswelt MOBIL LADSSCAPE auswählen",
        "helpText": null,
        "supportText": null,
        "position": 0,
        "displayInBackend": true,
        "readonly": false,
        "custom": false,
        "configured": true,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "pixel_emotion_mlid",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "integer",
        "elasticSearchType": {
            "type": "long"
        },
        "dbalType": "integer",
        "sqlType": "INT(11)",
        "entity": null,
        "arrayStore": null
    },
    {
        "id": 84,
        "label": "Einkaufswelt MOBIL PORTRAIT auswählen",
        "helpText": null,
        "supportText": null,
        "position": 0,
        "displayInBackend": true,
        "readonly": false,
        "custom": false,
        "configured": true,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "pixel_emotion_mpid",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "integer",
        "elasticSearchType": {
            "type": "long"
        },
        "dbalType": "integer",
        "sqlType": "INT(11)",
        "entity": null,
        "arrayStore": null
    },
    {
        "id": 81,
        "label": "Einkaufswelt TABLET LANDSSCAPE auswählen",
        "helpText": null,
        "supportText": null,
        "position": 0,
        "displayInBackend": true,
        "readonly": false,
        "custom": false,
        "configured": true,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "pixel_emotion_tlid",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "integer",
        "elasticSearchType": {
            "type": "long"
        },
        "dbalType": "integer",
        "sqlType": "INT(11)",
        "entity": null,
        "arrayStore": null
    },
    {
        "id": 82,
        "label": "Einkaufswelt TABLET PORTRAIT auswählen",
        "helpText": null,
        "supportText": null,
        "position": 0,
        "displayInBackend": true,
        "readonly": false,
        "custom": false,
        "configured": true,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "pixel_emotion_tpid",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "integer",
        "elasticSearchType": {
            "type": "long"
        },
        "dbalType": "integer",
        "sqlType": "INT(11)",
        "entity": null,
        "arrayStore": null
    },
    {
        "id": 79,
        "label": "Einkaufswelt aktivieren",
        "helpText": null,
        "supportText": null,
        "position": 0,
        "displayInBackend": true,
        "readonly": false,
        "custom": false,
        "configured": true,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "pixel_show_emotion",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "boolean",
        "elasticSearchType": {
            "type": "boolean"
        },
        "dbalType": "integer",
        "sqlType": "INT(1)",
        "entity": null,
        "arrayStore": null
    },
    {
        "id": 2,
        "label": "Hinweis",
        "helpText": "Freitext zur Anzeige auf der Detailseite",
        "supportText": "",
        "position": 1,
        "displayInBackend": true,
        "readonly": false,
        "custom": false,
        "configured": true,
        "translatable": true,
        "tableName": "s_articles_attributes",
        "columnName": "attr1",
        "defaultValue": null,
        "identifier": false,
        "core": true,
        "columnType": "text",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "TEXT",
        "entity": "NULL",
        "arrayStore": ""
    },
    {
        "id": 85,
        "label": "",
        "helpText": "",
        "supportText": "",
        "position": 1,
        "displayInBackend": false,
        "readonly": false,
        "custom": false,
        "configured": true,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "attr7",
        "defaultValue": "NULL",
        "identifier": false,
        "core": true,
        "columnType": "string",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "TEXT",
        "entity": "Shopware\\Models\\Category\\Category",
        "arrayStore": "[{\"key\":\"Scanner\",\"value\":\"1\"}]"
    },
    {
        "id": 173,
        "label": "Produktbeschreibung",
        "helpText": "",
        "supportText": "",
        "position": 1,
        "displayInBackend": true,
        "readonly": false,
        "custom": true,
        "configured": true,
        "translatable": true,
        "tableName": "s_articles_attributes",
        "columnName": "detail_maintext",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "text",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "TEXT",
        "entity": "",
        "arrayStore": ""
    },
    {
        "id": 182,
        "label": "Produkt Teaser/Claimer",
        "helpText": "",
        "supportText": "",
        "position": 1,
        "displayInBackend": true,
        "readonly": false,
        "custom": true,
        "configured": true,
        "translatable": true,
        "tableName": "s_articles_attributes",
        "columnName": "prod_descr_claimer",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "string",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "TEXT",
        "entity": "",
        "arrayStore": ""
    },
    {
        "id": 3,
        "label": "Produktinfo",
        "helpText": "Freitext zur Anzeige auf der Detailseite",
        "supportText": "",
        "position": 2,
        "displayInBackend": true,
        "readonly": false,
        "custom": false,
        "configured": true,
        "translatable": true,
        "tableName": "s_articles_attributes",
        "columnName": "attr2",
        "defaultValue": null,
        "identifier": false,
        "core": true,
        "columnType": "text",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "TEXT",
        "entity": "NULL",
        "arrayStore": ""
    },
    {
        "id": 176,
        "label": "Produktbild (in action)",
        "helpText": "",
        "supportText": "",
        "position": 2,
        "displayInBackend": true,
        "readonly": false,
        "custom": true,
        "configured": true,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "detail_inaction_img",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "string",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "TEXT",
        "entity": "",
        "arrayStore": ""
    },
    {
        "id": 185,
        "label": "Produktbild (overview/small)",
        "helpText": "",
        "supportText": "",
        "position": 2,
        "displayInBackend": true,
        "readonly": false,
        "custom": true,
        "configured": true,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "detail_small_img",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "string",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "TEXT",
        "entity": "",
        "arrayStore": ""
    },
    {
        "id": 179,
        "label": "Vorgänger-Serie",
        "helpText": "",
        "supportText": "",
        "position": 2,
        "displayInBackend": true,
        "readonly": false,
        "custom": true,
        "configured": true,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "predecessor_serie",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "string",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "TEXT",
        "entity": "",
        "arrayStore": ""
    },
    {
        "id": 1,
        "label": "Kommentar",
        "helpText": "Optionaler Kommentar",
        "supportText": "",
        "position": 3,
        "displayInBackend": true,
        "readonly": false,
        "custom": false,
        "configured": true,
        "translatable": true,
        "tableName": "s_articles_attributes",
        "columnName": "attr3",
        "defaultValue": null,
        "identifier": false,
        "core": true,
        "columnType": "text",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "TEXT",
        "entity": "NULL",
        "arrayStore": ""
    },
    {
        "id": 88,
        "label": "Bullet Point 1",
        "helpText": "",
        "supportText": "",
        "position": 3,
        "displayInBackend": true,
        "readonly": false,
        "custom": false,
        "configured": true,
        "translatable": true,
        "tableName": "s_articles_attributes",
        "columnName": "attr10",
        "defaultValue": "NULL",
        "identifier": false,
        "core": true,
        "columnType": "string",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "TEXT",
        "entity": null,
        "arrayStore": ""
    },
    {
        "id": 91,
        "label": "Bullet Point 2",
        "helpText": "",
        "supportText": "",
        "position": 3,
        "displayInBackend": true,
        "readonly": false,
        "custom": false,
        "configured": true,
        "translatable": true,
        "tableName": "s_articles_attributes",
        "columnName": "attr11",
        "defaultValue": "NULL",
        "identifier": false,
        "core": true,
        "columnType": "string",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "TEXT",
        "entity": null,
        "arrayStore": ""
    },
    {
        "id": 94,
        "label": "Bullet Point 3",
        "helpText": "",
        "supportText": "",
        "position": 3,
        "displayInBackend": true,
        "readonly": false,
        "custom": false,
        "configured": true,
        "translatable": true,
        "tableName": "s_articles_attributes",
        "columnName": "attr12",
        "defaultValue": "NULL",
        "identifier": false,
        "core": true,
        "columnType": "string",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "TEXT",
        "entity": null,
        "arrayStore": ""
    },
    {
        "id": 97,
        "label": "Bullet Point 4",
        "helpText": "",
        "supportText": "",
        "position": 3,
        "displayInBackend": true,
        "readonly": false,
        "custom": false,
        "configured": true,
        "translatable": true,
        "tableName": "s_articles_attributes",
        "columnName": "attr13",
        "defaultValue": "NULL",
        "identifier": false,
        "core": true,
        "columnType": "string",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "TEXT",
        "entity": null,
        "arrayStore": ""
    },
    {
        "id": 100,
        "label": "Bullet Point 5",
        "helpText": "",
        "supportText": "",
        "position": 3,
        "displayInBackend": true,
        "readonly": false,
        "custom": false,
        "configured": true,
        "translatable": true,
        "tableName": "s_articles_attributes",
        "columnName": "attr14",
        "defaultValue": "NULL",
        "identifier": false,
        "core": true,
        "columnType": "string",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "TEXT",
        "entity": null,
        "arrayStore": ""
    },
    {
        "id": 201,
        "label": "Bullet Point 6",
        "helpText": "",
        "supportText": "",
        "position": 3,
        "displayInBackend": true,
        "readonly": false,
        "custom": false,
        "configured": true,
        "translatable": true,
        "tableName": "s_articles_attributes",
        "columnName": "attr15",
        "defaultValue": "NULL",
        "identifier": false,
        "core": true,
        "columnType": "string",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "TEXT",
        "entity": null,
        "arrayStore": ""
    },
    {
        "id": 204,
        "label": "Bullet Point 7",
        "helpText": "",
        "supportText": "",
        "position": 3,
        "displayInBackend": true,
        "readonly": false,
        "custom": false,
        "configured": true,
        "translatable": true,
        "tableName": "s_articles_attributes",
        "columnName": "attr16",
        "defaultValue": "NULL",
        "identifier": false,
        "core": true,
        "columnType": "string",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "TEXT",
        "entity": null,
        "arrayStore": ""
    },
    {
        "id": 55,
        "label": "TopsellerStar",
        "helpText": "",
        "supportText": "",
        "position": 7,
        "displayInBackend": true,
        "readonly": false,
        "custom": false,
        "configured": true,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "attr5",
        "defaultValue": "NULL",
        "identifier": false,
        "core": true,
        "columnType": "boolean",
        "elasticSearchType": {
            "type": "boolean"
        },
        "dbalType": "integer",
        "sqlType": "INT(1)",
        "entity": null,
        "arrayStore": ""
    },
    {
        "id": 16,
        "label": "Preis auf Anfrage",
        "helpText": "Anstatt des \"Warenkorb\"-Buttons wird ein \"Preis auf Anfrage\"-Button angezeigt.",
        "supportText": "Preis auf Anfrage für diesen Artikel aktivieren.",
        "position": 10,
        "displayInBackend": true,
        "readonly": false,
        "custom": false,
        "configured": true,
        "translatable": true,
        "tableName": "s_articles_attributes",
        "columnName": "nnwebpriceonrequest",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "boolean",
        "elasticSearchType": {
            "type": "boolean"
        },
        "dbalType": "integer",
        "sqlType": "INT(1)",
        "entity": null,
        "arrayStore": null
    },
    {
        "id": 4,
        "label": "Externer Store",
        "helpText": "Link statt In den Warenkorb",
        "supportText": "",
        "position": 10,
        "displayInBackend": true,
        "readonly": false,
        "custom": false,
        "configured": true,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "webdhexternalstorelink",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "text",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "string",
        "sqlType": "TEXT",
        "entity": "NULL",
        "arrayStore": null
    },
    {
        "id": 123,
        "label": "Umtausch ausgeschlossen",
        "helpText": "",
        "supportText": "",
        "position": 24,
        "displayInBackend": true,
        "readonly": false,
        "custom": true,
        "configured": true,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "no_retoure",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "boolean",
        "elasticSearchType": {
            "type": "boolean"
        },
        "dbalType": "integer",
        "sqlType": "INT(1)",
        "entity": "",
        "arrayStore": ""
    },
    {
        "id": 222,
        "label": "Code Harmonisiertes System (HS)",
        "helpText": "Zolltarifnummer",
        "supportText": "",
        "position": 44,
        "displayInBackend": true,
        "readonly": false,
        "custom": true,
        "configured": true,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "code_harmon_system",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "string",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "TEXT",
        "entity": "",
        "arrayStore": ""
    },
    {
        "id": 120,
        "label": "Industrien",
        "helpText": "\"industrien\" eingeben und auswählen",
        "supportText": "Eigenschaften \"Industrien\"",
        "position": 50,
        "displayInBackend": true,
        "readonly": false,
        "custom": true,
        "configured": true,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "industries",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "multi_selection",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "MEDIUMTEXT",
        "entity": "Shopware\\Models\\Property\\Value",
        "arrayStore": ""
    },
    {
        "id": 216,
        "label": "neu - gebraucht",
        "helpText": "",
        "supportText": "Kondition >",
        "position": 70,
        "displayInBackend": true,
        "readonly": false,
        "custom": true,
        "configured": true,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "article_condition",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "single_selection",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "TEXT",
        "entity": "Shopware\\Models\\Property\\Value",
        "arrayStore": ""
    },
    {
        "id": 219,
        "label": "gleicher Artikel neu",
        "helpText": "",
        "supportText": "Artikel-ID",
        "position": 70,
        "displayInBackend": true,
        "readonly": false,
        "custom": true,
        "configured": true,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "article_neu",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "string",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "TEXT",
        "entity": "",
        "arrayStore": ""
    },
    {
        "id": 29,
        "label": "End of Life",
        "helpText": "",
        "supportText": "",
        "position": 71,
        "displayInBackend": true,
        "readonly": false,
        "custom": false,
        "configured": true,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "attr8",
        "defaultValue": null,
        "identifier": false,
        "core": true,
        "columnType": "date",
        "elasticSearchType": {
            "type": "date",
            "format": "yyyy-MM-dd"
        },
        "dbalType": "date",
        "sqlType": "DATE",
        "entity": null,
        "arrayStore": ""
    },
    {
        "id": 161,
        "label": "Ausgelistet (EOL)",
        "helpText": "",
        "supportText": "",
        "position": 72,
        "displayInBackend": true,
        "readonly": false,
        "custom": true,
        "configured": true,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "outlisted",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "boolean",
        "elasticSearchType": {
            "type": "boolean"
        },
        "dbalType": "integer",
        "sqlType": "INT(1)",
        "entity": "",
        "arrayStore": ""
    },
    {
        "id": 113,
        "label": "Alternative/Nachfolger",
        "helpText": "gekürzter Link (nicht Artikelnummer) ",
        "supportText": "",
        "position": 74,
        "displayInBackend": true,
        "readonly": false,
        "custom": true,
        "configured": true,
        "translatable": true,
        "tableName": "s_articles_attributes",
        "columnName": "substitute_article",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "string",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "TEXT",
        "entity": "Shopware\\Models\\Article\\Article",
        "arrayStore": ""
    },
    {
        "id": 70,
        "label": "Preis auf Anfrage",
        "helpText": "",
        "supportText": "",
        "position": 75,
        "displayInBackend": true,
        "readonly": false,
        "custom": true,
        "configured": true,
        "translatable": true,
        "tableName": "s_articles_attributes",
        "columnName": "preis_auf_anfrage",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "string",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "TEXT",
        "entity": "",
        "arrayStore": ""
    },
    {
        "id": 226,
        "label": "UVP",
        "helpText": "",
        "supportText": "Listenpreis",
        "position": 76,
        "displayInBackend": true,
        "readonly": false,
        "custom": true,
        "configured": true,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "article_uvp",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "float",
        "elasticSearchType": {
            "type": "double"
        },
        "dbalType": "float",
        "sqlType": "DOUBLE",
        "entity": "",
        "arrayStore": ""
    },
    {
        "id": 109,
        "label": "Video-Link",
        "helpText": "Direkter Link zu mp4 Video Datei",
        "supportText": "",
        "position": 80,
        "displayInBackend": true,
        "readonly": false,
        "custom": true,
        "configured": true,
        "translatable": true,
        "tableName": "s_articles_attributes",
        "columnName": "detail_videolink",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "string",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "TEXT",
        "entity": "",
        "arrayStore": ""
    },
    {
        "id": 208,
        "label": "Energy Efficiency Fiche",
        "helpText": "",
        "supportText": "Link",
        "position": 99,
        "displayInBackend": true,
        "readonly": false,
        "custom": true,
        "configured": true,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "energy_efficiency_doc",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "string",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "TEXT",
        "entity": "",
        "arrayStore": ""
    },
    {
        "id": 54,
        "label": "Necessary accessories",
        "helpText": null,
        "supportText": null,
        "position": 100,
        "displayInBackend": true,
        "readonly": false,
        "custom": true,
        "configured": true,
        "translatable": true,
        "tableName": "s_articles_attributes",
        "columnName": "arv_cross_selling_accessories",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "multi_selection",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "MEDIUMTEXT",
        "entity": "Shopware\\Models\\Article\\Article",
        "arrayStore": null
    },
    {
        "id": 103,
        "label": "Android Enterprise Recommended (AER)",
        "helpText": "",
        "supportText": "",
        "position": 100,
        "displayInBackend": true,
        "readonly": false,
        "custom": true,
        "configured": true,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "awcert_android_enterprise",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "boolean",
        "elasticSearchType": {
            "type": "boolean"
        },
        "dbalType": "integer",
        "sqlType": "INT(1)",
        "entity": "",
        "arrayStore": ""
    },
    {
        "id": 106,
        "label": "LifeGuard für Android",
        "helpText": "",
        "supportText": "",
        "position": 100,
        "displayInBackend": true,
        "readonly": false,
        "custom": true,
        "configured": true,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "awcert_android_lifeguard",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "boolean",
        "elasticSearchType": {
            "type": "boolean"
        },
        "dbalType": "integer",
        "sqlType": "INT(1)",
        "entity": "",
        "arrayStore": ""
    },
    {
        "id": 188,
        "label": "Zebra Printer Setup Utilities",
        "helpText": "",
        "supportText": "",
        "position": 100,
        "displayInBackend": true,
        "readonly": false,
        "custom": true,
        "configured": true,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "awcert_zebraprintersetup",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "boolean",
        "elasticSearchType": {
            "type": "boolean"
        },
        "dbalType": "integer",
        "sqlType": "INT(1)",
        "entity": "",
        "arrayStore": ""
    },
    {
        "id": 117,
        "label": "Zebra VisibilityIQ",
        "helpText": "",
        "supportText": "",
        "position": 100,
        "displayInBackend": true,
        "readonly": false,
        "custom": true,
        "configured": true,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "awcert_zebravisibilityiq",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "boolean",
        "elasticSearchType": {
            "type": "boolean"
        },
        "dbalType": "integer",
        "sqlType": "INT(1)",
        "entity": "",
        "arrayStore": ""
    },
    {
        "id": 6,
        "label": "Externer Link",
        "helpText": null,
        "supportText": "Externer Link zum Artikel",
        "position": 110,
        "displayInBackend": true,
        "readonly": false,
        "custom": false,
        "configured": true,
        "translatable": true,
        "tableName": "s_articles_attributes",
        "columnName": "emz_external_article_link_url",
        "defaultValue": null,
        "identifier": false,
        "core": false,
        "columnType": "string",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": "text",
        "sqlType": "TEXT",
        "entity": "Shopware\\Models\\Article\\Article",
        "arrayStore": null
    },
    {
        "id": null,
        "label": null,
        "helpText": null,
        "supportText": null,
        "position": null,
        "displayInBackend": false,
        "readonly": false,
        "custom": false,
        "configured": false,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "articleID",
        "defaultValue": "NULL",
        "identifier": false,
        "core": false,
        "columnType": "integer",
        "elasticSearchType": {
            "type": "long"
        },
        "dbalType": null,
        "sqlType": null,
        "entity": null,
        "arrayStore": null
    },
    {
        "id": null,
        "label": null,
        "helpText": null,
        "supportText": null,
        "position": null,
        "displayInBackend": false,
        "readonly": false,
        "custom": false,
        "configured": false,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "attr9",
        "defaultValue": "NULL",
        "identifier": false,
        "core": true,
        "columnType": "text",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": null,
        "sqlType": null,
        "entity": null,
        "arrayStore": null
    },
    {
        "id": null,
        "label": null,
        "helpText": null,
        "supportText": null,
        "position": null,
        "displayInBackend": false,
        "readonly": false,
        "custom": false,
        "configured": false,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "attr17",
        "defaultValue": "NULL",
        "identifier": false,
        "core": true,
        "columnType": "text",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": null,
        "sqlType": null,
        "entity": null,
        "arrayStore": null
    },
    {
        "id": null,
        "label": null,
        "helpText": null,
        "supportText": null,
        "position": null,
        "displayInBackend": false,
        "readonly": false,
        "custom": false,
        "configured": false,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "attr18",
        "defaultValue": "NULL",
        "identifier": false,
        "core": true,
        "columnType": "text",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": null,
        "sqlType": null,
        "entity": null,
        "arrayStore": null
    },
    {
        "id": null,
        "label": null,
        "helpText": null,
        "supportText": null,
        "position": null,
        "displayInBackend": false,
        "readonly": false,
        "custom": false,
        "configured": false,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "attr19",
        "defaultValue": "NULL",
        "identifier": false,
        "core": true,
        "columnType": "text",
        "elasticSearchType": {
            "type": "string"
        },
        "dbalType": null,
        "sqlType": null,
        "entity": null,
        "arrayStore": null
    },
    {
        "id": null,
        "label": null,
        "helpText": null,
        "supportText": null,
        "position": null,
        "displayInBackend": false,
        "readonly": false,
        "custom": false,
        "configured": false,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "pickware_incoming_stock",
        "defaultValue": "0",
        "identifier": false,
        "core": false,
        "columnType": "integer",
        "elasticSearchType": {
            "type": "long"
        },
        "dbalType": null,
        "sqlType": null,
        "entity": null,
        "arrayStore": null
    },
    {
        "id": null,
        "label": null,
        "helpText": null,
        "supportText": null,
        "position": null,
        "displayInBackend": false,
        "readonly": false,
        "custom": false,
        "configured": false,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "pickware_physical_stock_for_sale",
        "defaultValue": "0",
        "identifier": false,
        "core": false,
        "columnType": "integer",
        "elasticSearchType": {
            "type": "long"
        },
        "dbalType": null,
        "sqlType": null,
        "entity": null,
        "arrayStore": null
    },
    {
        "id": null,
        "label": null,
        "helpText": null,
        "supportText": null,
        "position": null,
        "displayInBackend": false,
        "readonly": false,
        "custom": false,
        "configured": false,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "pickware_stock_initialization_time",
        "defaultValue": "NULL",
        "identifier": false,
        "core": false,
        "columnType": "datetime",
        "elasticSearchType": {
            "type": "date",
            "format": "yyyy-MM-dd HH:mm:ss"
        },
        "dbalType": null,
        "sqlType": null,
        "entity": null,
        "arrayStore": null
    },
    {
        "id": null,
        "label": null,
        "helpText": null,
        "supportText": null,
        "position": null,
        "displayInBackend": false,
        "readonly": false,
        "custom": false,
        "configured": false,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "pickware_stock_initialized",
        "defaultValue": "0",
        "identifier": false,
        "core": false,
        "columnType": "boolean",
        "elasticSearchType": {
            "type": "boolean"
        },
        "dbalType": null,
        "sqlType": null,
        "entity": null,
        "arrayStore": null
    },
    {
        "id": null,
        "label": null,
        "helpText": null,
        "supportText": null,
        "position": null,
        "displayInBackend": false,
        "readonly": false,
        "custom": false,
        "configured": false,
        "translatable": false,
        "tableName": "s_articles_attributes",
        "columnName": "pickware_stock_management_disabled",
        "defaultValue": "0",
        "identifier": false,
        "core": false,
        "columnType": "boolean",
        "elasticSearchType": {
            "type": "boolean"
        },
        "dbalType": null,
        "sqlType": null,
        "entity": null,
        "arrayStore": null
    }
];
