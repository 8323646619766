import {Autocomplete, createFilterOptions, TextField} from "@mui/material";
import React, {useEffect} from "react";

interface SelectBoxWithFillableProps {
    label: string;
    onChange: (value: string) => void;
    data: PropertyFilterType[];
    style?: any;
    value: string;
}
interface PropertyFilterType {
    title: string;
}

const filter = createFilterOptions<PropertyFilterType>();

export default function SelectBoxWithFillable(props: SelectBoxWithFillableProps) {
    const [value, setValue] = React.useState<PropertyFilterType | null>({
        title: props.value
    });

    useEffect(() => {
        if(props.onChange){
            props.onChange(value?.title ?? '');
        }
    }, [value]);

    return (
        <Autocomplete
            style={props.style}
            value={value}
            onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    setValue({
                        title: newValue,
                    });
                } else {
                    setValue(newValue);
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.title);
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        title: `${inputValue}`,
                    });
                }

                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={props.data}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Regular option
                return option.title;
            }}
            renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                    <li key={key} {...optionProps}>
                        {option.title}
                    </li>
                );
            }}
            sx={{ width: 300 }}
            freeSolo
            renderInput={(params) => (
                <TextField {...params} label={props.label} />
            )}
        />
    );
}
