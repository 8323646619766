import {
    Button,
    TextField
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import RefreshIcon from "@mui/icons-material/Refresh";
import TreeItem from '@mui/lab/TreeItem';
import { category } from "../repository/category";

import axios from "axios";
import { environment } from "../environment";

type Props = {
    categories: category[],
    onChange: (selected: string[]) => void;
    multiple?: boolean;
    saveMargens: (cats: category[]) => void
}

type margen = {
    categegory: category,
    value: string
}

export default function CategorySelectBox(props: Props) {

    const [margen, setMargen] = useState<any>()
    const [loadedMarge, setLoadedMargen] = useState<any>()
    const [selected, setSelected] = useState<any>()
    const [expanded, setExpanded] = useState<any>()

    useEffect(() => {
        loadMargen()
    }, [])

    const loadMargen = () => {
        axios.get(environment.ownBackendURL + 'margen').then(res => {
            console.log(res)
            setMargen(res.data)
        })
    }

    //only expand if icon was clicked
    const handleToggle = (event: any, nodeIds: any) => {
        if (event.target.closest('.someClass')) {
            setExpanded(nodeIds);
        }
    };

    //only select if icon wasn't clicked
    const handleSelect = (event: any, accountId: any) => {
        event.persist()
        let iconClicked = event.target.closest(".MuiTreeItem-iconContainer")
        if (!iconClicked) {
            setSelected(accountId);
        }
    };

    const findSubsTree = useCallback((categoryID: number, comingFromParent?: boolean) => {
        const parent = props.categories.find((parentCat) => parentCat.id === categoryID);
        const subs = props.categories.filter((category) => category.parentId === categoryID);
        if (parent === undefined) {
            return;
        }

        if (comingFromParent) {
            return (<>{subs.map((category) => (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <TreeItem nodeId={category.id.toString()} label={
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 4, alignItems: 'center', height: 60 }}>
                            <div style={{ marginRight: 12 }}>{category.name}</div>
                        </div>
                    }>
                        {findSubsTree(category.id, true)}
                    </TreeItem>
                    <TextField type={'number'} InputLabelProps={{ shrink: true }} id="outlined-basic" onChange={(e) => setValueForCategoryMarge(category, e.target.value)} value={getTextFieldValue(category)} label="Marge in %" variant="outlined" />
                    {/* <Button onClick={() => { console.log('djkfh') }} variant="outlined">Outlined</Button> */}
                </div >
            ))
            }</>)
        }

        return (
            <TreeItem nodeId={parent.id.toString()} label={parent.name
            }>
                {subs.map((category) => (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <TreeItem nodeId={category.id.toString()} label={
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 4, alignItems: 'center', height: 60 }}>
                                <div style={{ marginRight: 12 }}>{category.name}</div>
                            </div>
                        }>
                            {findSubsTree(category.id, true)}
                        </TreeItem>
                        <TextField type={'number'} InputLabelProps={{ shrink: true }} id="outlined-basic" onChange={(e) => setValueForCategoryMarge(category, e.target.value)} value={getTextFieldValue(category)} label="Marge in %" variant="outlined" />
                    </div>
                ))}
            </TreeItem>
        )
    }, [props.categories, setValueForCategoryMarge]);

    function saveMarge(): void {
        axios.put(environment.ownBackendURL + 'margen', { margen }).then(res => console.log(res.data))
    }

    function saveAndApply() {
        let catsCopy = props.categories
        if (margen) {
            catsCopy.forEach(cat => {
                if (margen[cat.name] !== undefined) {
                    cat.marge = Number(margen[cat.name])
                }
            })
        }
        saveMarge()
        props.saveMargens(catsCopy)
    }

    function save() {
        saveMarge()
    }

    return (
        <div style={{ height: '100%' }}>
            {/* <Button onClick={() => saveAndApply()} color="success" style={{ position: 'absolute', right: 12, top: 12, }} variant="contained">Speichern & anwenden</Button> */}
            <Button onClick={() => save()} style={{ position: 'absolute', right: 220, top: 12, }} variant="contained">Speichern</Button>
            <Button onClick={() => loadMargen()} style={{ position: 'absolute', left: 'calc(50% - 60)', top: 12, }} variant="contained"> Neu laden<RefreshIcon /></Button>
            {margen &&
                <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    multiSelect={props.multiple}
                    onNodeToggle={handleToggle}
                    expanded={expanded}
                    sx={{ height: '80%', flexGrow: 1, width: '99%', overflowX: 'hidden', overflowY: 'auto', textAlign: 'initial' }}
                >
                    {props.categories.filter(cat => cat.parentId === null).map((category) => findSubsTree(category.id))}
                </TreeView>}
        </div >
    )

    function setValueForCategoryMarge(category: category, value: string): void {
        let newMargen = margen
        if (newMargen) {
            newMargen[category.name] = value
        } else {
            newMargen = {}
            newMargen[category.name] = value
        }
        setMargen({ ...newMargen })

    }

    function getTextFieldValue(category: category): unknown {
        console.log(category)
        return margen ? margen[category.name] : undefined
    }
}

const style = {
    treeView: {
        textfield: { height: 40 }
    }
}
