import {Button, Checkbox, FormControlLabel, FormGroup, TextField} from "@mui/material";
import axios from "axios";
import {useCallback, useEffect, useState} from "react";
import { environment } from "../environment";
import {Article, getConfig, updateConfig} from "../repository/article";

export default function AutoSyncPopup(props: { close: () => void;}) {
    const [loading, setLoading] = useState(true)
    const [ek, setEk] = useState(true)
    const [margen, setMargen] = useState(true)
    const [promo, setPromo] = useState(true)
    const [outlist, setOutlist] = useState(true);
    const [percentage, setPercentage] = useState('10');

    const getConfigData = useCallback(async () => {

        const data = await getConfig();

        setEk(data.ek === 1);
        setMargen(data.margen === 1 );
        setPromo(data.promo === 1);
        setOutlist(data.outlist === 1);
        setPercentage(data.percentage);


    }, []);

    useEffect(() => {
        getConfigData().then(undefined);
    }, []);

    const saveConfigData = useCallback(async () => {

       await updateConfig({
            margen: margen ? 1 : 0,
            promo: promo ? 1 :0,
            ek: ek ? 1 : 0,
            outlist: outlist ? 1 : 0,
            percentage: Number(percentage)
        });

       props.close();


    }, [margen, promo, ek, outlist, percentage]);



    return (
        <div style={{ position: 'fixed', top: 0, left: 0, backgroundColor: 'rgba(0,0,0, 0.4)', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ position: 'relative', height: '50%', width: '60%', backgroundColor: '#fff', display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexDirection: 'column' }}>
                <Button onClick={() => props.close()} color="error" style={{ position: 'absolute', width: 80, left: 10, top: 10 }} variant="contained">X</Button>
                <h2>Which data should be automatically sync ? </h2>
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={promo} onChange={(event) => setPromo(event.target.checked)} />} label="Sonderpreise" />
                    <FormControlLabel control={<Checkbox checked={ek} onChange={(event) => setEk(event.target.checked)} />} label="Einkaufspreis und Bestand" />
                    <FormControlLabel control={<Checkbox checked={margen} onChange={(event) => setMargen(event.target.checked)} />} label="Margen" />
                    <FormControlLabel control={<Checkbox checked={outlist} onChange={(event) => setOutlist(event.target.checked)} />} label="Outlist articles that are in not in csv" />
                    <TextField value={percentage} onChange={(event) => setPercentage(event.target.value)} label="Percantage to drop price on theracon"  />
                </FormGroup>
                <Button onClick={saveConfigData} color="success" style={{ position: 'relative', width: 120 }} variant="contained">Save Changes</Button>
            </div>

        </div>
    )
}
