import axios from "axios"
import { environment } from "../environment"
import { Article } from "./article"

export type category = {
    id: number,
    name: string,
    position: number,
    parentId: number,
    childrenCount: number
    articleCount: number
    active?: boolean,
    mediaId?: number,
    marge: number | string | undefined
}

export type supplier =  {
    "metaTitle":  string;
    "metaDescription": string;
    "metaKeywords": string;
    "id": number;
    "name": string;
    "image": string;
    "link": string;
    "description": string;
    "changed": string;
}

export interface categoryInnerData {
    "sortingIds": string,
    "hideSortings": boolean,
    "facetIds": string,
    "shops": null,
    "id": number,
    "parentId": number,
    "streamId": null,
    "name":string,
    "position": number,
    "metaTitle": string
    "metaKeywords": string
    "metaDescription": string
    "cmsHeadline": string
    "cmsText": string
    "active": boolean,
    "template": null,
    "productBoxLayout": null,
    "blog": boolean,
    "path": string,
    "external": "",
    "externalTarget": "",
    "hideFilter": boolean,
    "hideTop": boolean,
    "changed": boolean
    "added": string
    "mediaId": null,
    "attribute": {
        "id": number,
        "categoryId": number,
        "attribute1": null | string,
        "attribute2": null | string,
        "attribute3": null | string,
        "attribute4": null | string,
        "attribute5": null | string,
        "attribute6": null | string,
        "kcExcludePropertyOptions": string
    },
    "emotions": [],
    "media": null,
    "customerGroups": [],
    "manualSorting": [],
    "0": {
        "sortingIds": string,
        "hideSortings": boolean,
        "facetIds": string,
        "shops": null,
        "id": number,
        "parentId": number,
        "streamId": null,
        "name":string,
        "position": number,
        "metaTitle": string
        "metaKeywords": string
        "metaDescription": string
        "cmsHeadline": string
        "cmsText": string
        "active": boolean,
        "template": null,
        "productBoxLayout": null,
        "blog": boolean,
        "path": string,
        "external": "",
        "externalTarget": "",
        "hideFilter": boolean,
        "hideTop": boolean,
        "changed": boolean
        "added": string
        "mediaId": null,
        "attribute": {
            "id": number,
            "categoryId": number,
            "attribute1": null | string,
            "attribute2": null | string,
            "attribute3": null | string,
            "attribute4": null | string,
            "attribute5": null | string,
            "attribute6": null | string,
            "kcExcludePropertyOptions": string
        },
        "emotions": [],
        "media": null,
        "customerGroups": [],
        "manualSorting": [],
    },
    "childrenCount": string,
    "articleCount": string
}

export interface PropertiesList {
    "id": number;
    "name": string;
    "position": number,
    "comparable": boolean,
    "sortMode": number,
    "options": PropertyOptionInterface[]
}
export interface PropertyOptionInterface {
    "id": number,
    "name": string,
    "filterable": boolean
}

export interface ManufacturersInterface {
    "metaTitle": string;
    "metaDescription": string;
    "metaKeywords": string;
    "id": number;
    "name": string;
    "image": string;
    "link": string;
    "description": string;
    "changed": string;
}

export interface TaxesInterface {
    "id": number;
    "tax": string;
    "name": string;
}

export function getCategories(): Promise<category[]> {
    return new Promise((resolve, reject) => {
        const url = environment.backendURL + 'categories'
        axios.get(url, { auth: { password: 'wit58vLrcqH5sN4uVOFgTII4cMQlzgRblT2tpArz', username: 'kemal' } }).then(res => {
            const categories: category[] = res.data.data
                .map((res: any) => {
                    return { id: res.id, name: res.name, position: res.position, parentId: res.parentId, childrenCount: Number(res.childrenCount), articleCount: res.articleCount, marge: '' }
                })
            resolve(categories)
        })
    })
}


export function getCategoryData(categoryId: number): Promise<categoryInnerData> {

    return new Promise((resolve) => {

        const url = environment.backendURL + 'categories';

        axios.get(url + `/${categoryId}`, { auth: { password: 'wit58vLrcqH5sN4uVOFgTII4cMQlzgRblT2tpArz', username: 'kemal' } }).then((res) => {
            return resolve(res.data.data as categoryInnerData);
        })

    })

}


export function getManufacturers(): Promise<ManufacturersInterface[]>{

    //
    return new Promise((resolve) => {

        const url = environment.backendURL + 'manufacturers';

        axios.get(url, { auth: { password: 'wit58vLrcqH5sN4uVOFgTII4cMQlzgRblT2tpArz', username: 'kemal' } }).then((res) => {
            return resolve(res.data.data as ManufacturersInterface[]);
        })

    })
}


export function getTaxDetails(): Promise<ManufacturersInterface[]>{

    //
    return new Promise((resolve) => {

        const url = environment.backendURL + 'articles/22340,2250';

        axios.get(url, { auth: { password: 'wit58vLrcqH5sN4uVOFgTII4cMQlzgRblT2tpArz', username: 'kemal' } }).then((res) => {
            return resolve(res.data.data as ManufacturersInterface[]);
        })

    })
}

export function getSeries(): Promise<any[]>{

    //
    return new Promise((resolve) => {

        const url = environment.ownBackendURL + 'getSeries';

        axios.get(url).then((res) => {
            return resolve(res.data as any[]);
        })

    })
}
export function addSerie(data: any): Promise<any[]>{

    //
    return new Promise((resolve) => {

        const url = environment.ownBackendURL + 'addSerie';

        axios.post(url, {serieData: data}).then((res) => {
            return resolve(res.data.data as any[]);
        })

    })
}


export function getAttributeList(){

    //
    return new Promise((resolve) => {

        const url = environment.backendURL + 'propertyGroups';

        axios.get(url, { auth: { password: 'wit58vLrcqH5sN4uVOFgTII4cMQlzgRblT2tpArz', username: 'kemal' } }).then((res) => {
            return resolve(res.data.data as PropertiesList[]);
        })

    })
}

export function allArticleDetails() {

    return new Promise((resolve, reject) => {
            axios.get(environment.ownBackendURL + 'allArticleDetails').then(res => {
                resolve(res.data)
            })
        })
}


export function getSuppliers(): Promise<supplier[]> {
    return new Promise((resolve, reject) => {
        const url = environment.backendURL + 'manufacturers'
        axios.get(url, { auth: { password: 'wit58vLrcqH5sN4uVOFgTII4cMQlzgRblT2tpArz', username: 'kemal' } }).then(res => {
            resolve(res.data.data);
            // const categories: category[] = res.data.data
            //     .map((res: any) => {
            //         return { id: res.id, name: res.name, position: res.position, parentId: res.parentId, childrenCount: Number(res.childrenCount), articleCount: res.articleCount, marge: '' }
            //     })
            // resolve(categories)
        })
    })
}


export function loadMargen(): Promise<any> {
    return new Promise((resolve, reject) => {
        axios.get(environment.ownBackendURL + 'margen').then(res => {
            resolve(res.data)
        })
    })
}

export function isArticleUnderCat(article: Article, cat: category, categories: category[]) {

    let result: boolean = false

    if (article.category.length === 0) {
        return false
    }
    if (article.subCategory.length === 0) {
        return false
    }

    let cats = article.category.find(_cat => _cat === cat.id)
    let subCats = article.category.find(_subCat => _subCat === cat.id)
    if (cats || subCats) {
        return true
    }

    const rootID: number | undefined = categories.find(_cat => _cat.name === "Root")?.id
    article.subCategory.some(subCat => {
        let parentID = subCat
        while (parentID !== rootID && result === false) {
            if (isCatSubOrCatOfArt(parentID, cat)) {
                result = true
            } else {
                let foundParent = categories.find(_cat => Number(_cat.id) === Number(parentID))
                if (foundParent) {
                    parentID = foundParent.parentId
                } else {
                    if (rootID) {
                        result = false
                    }
                }
            }
        }
        return true
    });

    return result
}

function isCatSubOrCatOfArt(subCat: number, cat: category) {
    return Number(subCat) === Number(cat.id)
}

export function importCategories(csv: any) {
    const csvData = csv.map((art: any) => art.data)
    const allCategories: category[] = csvData.map((cat: any) => { return { id: cat[0], name: cat[1], position: cat[2], parentId: cat[3], childrenCount: cat[4], articleCount: cat[5], marge: cat[6] === '' || cat[6] === 'marge' ? undefined : Number(cat[6]) } })
    return allCategories
}

