import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import axios from "axios";
import {environment} from "../../../environment";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function InputFileUpload(props: {
    onUpload: (id: number) => void;
}) {
    const handleFileChange = async (event: any) => {
        const file = event.target.files[0];
        if (!file) {
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('album', "-1");
        formData.append("description", " ");

        try {

            await axios.post(`${environment.backendURL}media`, formData, {
                auth: { password: 'wit58vLrcqH5sN4uVOFgTII4cMQlzgRblT2tpArz', username: 'kemal' }
            }).then((res) => {
               props.onUpload(res.data.data.id);
            });

        } catch (error) {
            console.error('Error during file upload:', error);
        }
    };

    return (
        <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
        >
            Upload file
            <VisuallyHiddenInput type="file" onChange={handleFileChange} />
        </Button>
    );
}
