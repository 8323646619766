import React, {useCallback, useEffect, useState} from 'react';
import {AppBar, Tabs, Tab, Typography, Box, Button} from '@mui/material';
import { styled } from '@mui/material/styles';
import CategorySelection from "./CategorySelection";
import {
    addSerie,
    allArticleDetails,
    category,
    ManufacturersInterface,
    PropertiesList,
    TaxesInterface
} from "../../repository/category";
import CategoryBox from "./CategoryBox";
import AttributesSelection from "./AttributesSelection";
import ProductDetails from "./ProductDetails";
import InputFileUpload from "./AdditionalData/FileUpload";
import moment from "moment";
import axios from "axios";
import {environment} from "../../environment";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface Price {
    customerGroupKey: string;
    from: number;
    to: string;
    price: number;
    pseudoPrice: number;
    percent: number;
}

interface MainDetail {
    number: string;
    purchasePrice: string;
    supplierNumber: string;
    weight: string;
    width: string;
    len: string;
    height: string;
    ean: string;
    purchaseUnit: string;
    descriptionLong: string;
    referenceUnit: string;
    packUnit: string;
    shippingTime: string;
    prices: Price[];
    attribute: Record<string, any>;
    unitId: number;
    inStock: number;
    position: number;
    minPurchase: number;
    purchaseSteps: number;
    maxPurchase: number;
    active: boolean;
    shippingFree: boolean;
}

interface Category {
    id: number;
}

interface PropertyValue {
    option: {
        name: string;
    };
    value: string;
}

export interface Product {
    name: string;
    taxId: number;
    mainDetail: MainDetail;
    supplierId: string;
    filterGroupId: number;
    description: string;
    descriptionLong: string;
    added: string; // Consider using Date type if you plan to handle Date objects
    active: boolean;
    pseudoSales: number;
    highlight: boolean;
    keywords: string;
    metaTitle: string;
    priceGroupActive: boolean;
    lastStock: boolean;
    crossBundleLook: boolean;
    notification: boolean;
    template: string;
    mode: number;
    images: { mediaId: number }[]; // Assuming images are represented by URLs or file paths
    categories: Category[];
    propertyValues: PropertyValue[];
}


const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const a11yProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const CustomTab = styled(Tab)(({ theme }) => ({
    '&.Mui-selected': {
        backgroundColor: theme.palette.action.selected,
        color: theme.palette.primary.main,
    },
}));

interface TabListProps {
    categories: category[];

    selectedCategories: number[];
    setSelectedCategories:  React.Dispatch<React.SetStateAction<number[]>>

    attributeList: PropertiesList[];
    setSelectedAttribute: React.Dispatch<React.SetStateAction<PropertiesList | undefined>>
    selectedAttribute: PropertiesList | undefined;
    attributeValues: {[key: number]: string};
    setAttributeValues: React.Dispatch<React.SetStateAction<{[key: number]: string}>>;


    manufacturers: ManufacturersInterface[];
    taxes: TaxesInterface[];

    type: 'create' | 'post';
    productData?: any;
}

export default function TabList(props: TabListProps) {
    const [value, setValue] = React.useState(0);
    const [loadAllArticles, setLoadAllArticles] = useState<any[]>([]);
    const [uploadedFiles, setUploadedFiles] = useState<number[]>([]);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        allArticleDetails().then((res) => setLoadAllArticles(res as any[]));
    }, []);


    const [productData, setProductData ] = useState<Product>({
        name: '',
        taxId: 0,
        mainDetail: {
            number: '',
            purchasePrice: '',
            supplierNumber: '',
            weight: '',
            width: '',
            len:'',
            height: '',
            ean: '',
            purchaseUnit: '',
            descriptionLong: '',
            referenceUnit: '',
            packUnit: '',
            shippingTime: '',
            prices: [
                {
                    "customerGroupKey": "EK",
                    "from": 1,
                    "to": "beliebig",
                    "price": 0,
                    "pseudoPrice": 0,
                    "percent": 0
                }
            ],
            attribute: {},
            unitId: 9,
            inStock: 0,
            position: 0,
            minPurchase: 0,
            purchaseSteps: 0,
            maxPurchase: 0,
            active: true,
            shippingFree: false,
        },
        supplierId: '0',
        filterGroupId: 0,
        description: '',
        descriptionLong: '',
        added: moment().format("YYYY/MM/DD"),
        active: true,
        pseudoSales: 0,
        highlight: false,
        keywords: '',
        metaTitle: '',
        priceGroupActive: false,
        lastStock: false,
        crossBundleLook: false,
        notification: false,
        template: '',
        mode: 0,
        images: [],
        categories: [],
        propertyValues: []
    });

    useEffect(() => {
        if(props.type === 'post' && props.productData){
            setProductData(props.productData as Product);
        }
    }, []);


    const addSerieData = useCallback(() => {
        addSerie(JSON.stringify(productData)).then(() => {
            alert("Serie Successfully Created");
        });

    }, [productData]);

    const sendProduct = useCallback(() => {


        productData.propertyValues = productData.propertyValues.filter((i) => i.value !== '');

        const attributeObjects = Object.keys(productData.mainDetail.attribute);

        console.log(attributeObjects, productData.mainDetail.attribute);

        attributeObjects.forEach((attrObjs) => {

            if(typeof productData.mainDetail.attribute[attrObjs] === 'string'){
                productData.mainDetail.attribute[attrObjs] = productData.mainDetail.attribute[attrObjs];
            }else if(Array.isArray(productData.mainDetail.attribute[attrObjs])){
                productData.mainDetail.attribute[attrObjs] = productData.mainDetail.attribute[attrObjs].map((i: any) => i.id).join("|")
            }else if(typeof productData.mainDetail.attribute[attrObjs] === 'object'){
                if(productData.mainDetail.attribute[attrObjs]?.hasOwnProperty('id')) {
                    productData.mainDetail.attribute[attrObjs] = productData.mainDetail.attribute[attrObjs].id;
                }
            }





        });


        axios.post(environment.backendURL + 'articles', productData, { auth: { password: 'wit58vLrcqH5sN4uVOFgTII4cMQlzgRblT2tpArz', username: 'kemal' } }).then((res) => {
            alert("Product Successfully Created");
        }).catch((err) => {
            console.log(err);
        })


    }, [productData]);


    return (
        <div>


            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <CustomTab style={{color: "white"}} label="Product" {...a11yProps(0)} />
                    <CustomTab style={{color: "white"}} label="Images" {...a11yProps(1)} />
                    <CustomTab style={{color: "white"}} label="Categories" {...a11yProps(2)} />
                    <CustomTab style={{color: "white"}} label="Attributes" {...a11yProps(3)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <ProductDetails  productData={productData} setProductData={setProductData}  categories={props.categories} taxes={props.taxes} manufacturers={props.manufacturers} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <InputFileUpload onUpload={(uploadedId) => {
                    setUploadedFiles((prev) => {
                        return [...prev, uploadedId];
                    })

                    setProductData((prev) => {
                        return {...prev, images: [...prev.images, {mediaId: uploadedId}]};
                    })
                }} />
                Uploaded Media Ids: {productData.images.map((i) => i.mediaId).join(", ")}
            </TabPanel>
            <TabPanel value={value} index={2}>
                <CategoryBox productData={productData} setProductData={setProductData} categories={props.categories} />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <AttributesSelection productData={productData} setProductData={setProductData}  articleData={loadAllArticles} setSelectedAttribute={props.setSelectedAttribute} selectedAttribute={props.selectedAttribute} attributeValues={props.attributeValues} attributeList={props.attributeList} setAttributeValues={props.setAttributeValues}  />
            </TabPanel>

            <div>
                {props.type === 'create' && (
                    <Button onClick={addSerieData} variant="contained">Save Serie </Button>
                )}
                {props.type === 'post' && (
                    <Button onClick={sendProduct} variant="contained">Create Product</Button>
                )}
            </div>

        </div>
    );
}
