import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { CSVReader } from 'react-papaparse'
import {
  Article,
  calculateArticle, getAllData, getLastSync,

  getPromoData, getSupplierData, lastSyncAuto,
  moveToOutListArticle,
  setNewPriceForArticle2, updateLastSync,
  updateStockForAllArticle
} from '../src/repository/article'
import React, {useCallback, useEffect, useState} from 'react';
import { getCSVArticleWhichAreNotInShop, getDifferntPriceArticle, getIDMatchingArticles, getShopArticleWhichAreNotInCSV, JSONToCSVConvertor } from './repository/csv';
import { category, getCategories, getSuppliers, importCategories, loadMargen, supplier } from './repository/category';
import CategorySelectBox from './Components/CategorySelectBox';
import LeftIcon from "@mui/icons-material/ArrowLeft";

import { Button } from '@mui/material';
import NotPresentInCSV from "./NotPresentInCSV";
import { Login } from './Components/Login';
import PromoPage from './Components/PromoPage';
import UpdatePopUp from './Components/UpdatePopUp';
import moment from 'moment';
import axios from 'axios';
import { environment } from './environment';
import SuppliersPage from "./Components/SuppliersPage";
import AutoSyncPopup from "./Components/AutoSyncPopup";
import CategoryAttributes from "./Components/CategoryAttributes";

enum LoadState {
  NEUTRAL = "",
  LOADING = "LÄDT",
  LOADED = "GELADEN",
  CANCELED = "ABGEBROCHEN"
}

function App() {

  const [progress, setProgress] = React.useState(false);
  const [ekAndStockProgress, setEkAndStockProgress] = React.useState(false);
  const [margenProgress, setMargenProgress] = React.useState(false);
  const [articles, setArticles] = React.useState<Article[]>([]);
  const [missingInCSV, setMissingInCSV] = React.useState<Article[]>([]);
  const [missinginShop, setMissinginShop] = React.useState<Article[]>([]);
  const [differentPrice, setDifferentPrice] = React.useState<{ HerstellerNr: string; csvPrice: number; shopPrice: number; }[]>([]);
  const [matchingArticles, setMatchingArticles] = React.useState<Article[]>([]);
  const [categories, setCategories] = React.useState<category[]>([]);
  const [loadedCategories, setLoadedCategories] = React.useState<category[]>();
  const [csvDroped, setCSVDroped] = React.useState<any>();
  const [loggedIN, setLoggedIN] = React.useState<boolean>(false);
  const [username, setUsername] = React.useState<string>();
  const [psw, setPSW] = React.useState<string>();
  const [loginError, setLoginError] = React.useState<boolean>();
  const [margenViewOpen, setMargenViewOpen] = React.useState<boolean>();
  const [notPresentView, setNotPresentView] = useState(false);
  const [promoView, setPromoView] = useState(false);
  const [updatePopUpOpen, setUpdatePopUpOpen] = useState(false);
  const [autoPopupOpen, setAutoPopupOpen] = useState(false);

  const [loadedSuppliers, setLoadedSuppliers] = useState([]);
  const [suppliersView, setSuppliersView] = useState(false);
  const [supplierList, setSupplierList] = useState<supplier[]>([]);
  const [lastSync, setLastSync] = useState('');
  const [lastSyncOnline, setLastSyncOnline] = useState('');
  const [showCategoryBox, setShowCategoryBox] = useState(false);

  const [supplierLoadedState, setSupplierLoadedState] = useState<{ blueStar: LoadState, ingram: LoadState, jarltech: LoadState, shop: LoadState }>({ blueStar: LoadState.NEUTRAL, ingram: LoadState.NEUTRAL, jarltech: LoadState.NEUTRAL, shop: LoadState.NEUTRAL });




  useEffect(() => {
    getCategories().then(cats => {
      setLoadedCategories(cats)
    });
    getSuppliers().then((suppliers: any) => {
      setLoadedSuppliers(suppliers);
    });
    getSupplierData().then((sup) => {
      setSupplierList(JSON.parse(JSON.stringify(sup)));
    });
    getLastSync().then((sup) => {
      setLastSync(sup);
    });

    lastSyncAuto().then((sup) => {
      setLastSyncOnline(sup);
    })
  }, [])


  function getBlueStarData() {
    return new Promise<Article[]>((resolve, reject) => {
      setSupplierLoadedState(prevState => ({ ...prevState, blueStar: LoadState.LOADING }))
      axios.get(environment.ownBackendURL + 'blueStarData', { timeout: 10 * 60 * 1000 }).then((res: any) => {
        setSupplierLoadedState(prevState => ({ ...prevState, blueStar: LoadState.LOADED }))
        resolve(res.data)
      }).catch(err => {

        setSupplierLoadedState(prevState => ({ ...prevState, blueStar: LoadState.CANCELED }))
      })
    })
  }

  function getIngramData() {
    return new Promise<Article[]>((resolve, reject) => {
      setSupplierLoadedState(prevState => ({ ...prevState, ingram: LoadState.LOADING }))
      axios.get(environment.ownBackendURL + 'ingramData', { timeout: 10 * 60 * 1000 }).then((res: any) => {
        setSupplierLoadedState(prevState => ({ ...prevState, ingram: LoadState.LOADED }))
        resolve(res.data)
      }).catch(err => {

        setSupplierLoadedState(prevState => ({ ...prevState, ingram: LoadState.CANCELED }))
      })
    })
  }


  async function loadShopArticles() {
    setProgress(true)
    setSupplierLoadedState(prevState => ({ ...prevState, shop: LoadState.LOADING }))
    let loadedArticles = await calculateArticle()
    setSupplierLoadedState(prevState => ({ ...prevState, shop: LoadState.LOADED }))
    setArticles(loadedArticles)
    setProgress(false)
    setMatchingArticles([])
    setMissingInCSV([])
    setMissinginShop([])
    setDifferentPrice([])
  }

  async function handleOnBestandDrop(csv: any) {
    setArticles([])
    setCSVDroped(csv)

    setProgress(true)
    let loadedArticles: Article[];
    try {
      setSupplierLoadedState(prevState => ({ ...prevState, shop: LoadState.LOADING }))
      loadedArticles = await calculateArticle()
      setSupplierLoadedState(prevState => ({ ...prevState, shop: LoadState.LOADED }))
    } catch (err) {
      setSupplierLoadedState(prevState => ({ ...prevState, shop: LoadState.CANCELED }))
    }

    // let blueStarArticle;
    // let ingramArticle;

    getBlueStarData().then(async (blueStarArticle: Article[]) => {
      getIngramData().then(async (ingramArticle: Article[]) => {
        let matchingArticlesBlueStar = await getIDMatchingArticles(csv, loadedArticles, blueStarArticle)
        let matchingArticlesIngram = await getIDMatchingArticles(csv, loadedArticles, ingramArticle)
        let matchingArticlesJarltech = await getIDMatchingArticles(csv, loadedArticles)
        let matchingArticlesCalculated = prepareArrayOfSources([matchingArticlesJarltech, matchingArticlesBlueStar, matchingArticlesIngram])
        setMatchingArticles(matchingArticlesCalculated)
        //console.log(matchingArticlesCalculated)
        //console.log(matchingArticlesCalculated.length)
        setArticles(loadedArticles)

        console.log("ARTICLES CALCULATED", matchingArticlesCalculated);
        let _missingInCSV = getShopArticleWhichAreNotInCSV(csv, matchingArticlesCalculated)
        console.log("missing in csv", _missingInCSV);
        let _missinginShop = getCSVArticleWhichAreNotInShop(csv, loadedArticles)
        let _differentPrice = getDifferntPriceArticle(csv, loadedArticles)

        setProgress(false)
        setMissingInCSV(_missingInCSV)
        setMissinginShop(_missinginShop)
        setDifferentPrice(_differentPrice)
      }).catch(err => {

      })
    }).catch(err => {
    })
  }

  const loadDataFromBackend = useCallback(async () => {

    const myData = await getAllData();

    setMatchingArticles(myData.prepData ?? [])
    setArticles(myData.loadedData ?? [])
    setMissingInCSV(myData.shopArticlesButNotInCSV ?? [])
    setMissinginShop(myData.inCSVButNotInShop ?? [])
    setDifferentPrice(myData.diffPriceArticle ?? []);
    setProgress(false)

  },[]);

  useEffect(() => {
    loadDataFromBackend();
  }, []);


  function prepareArrayOfSources(sources: Article[][]): Article[] {
    const mergedArticles: { [supplierNumber: string]: Article[] } = {};

    // Merge articles from different suppliers based on their supplierNumber
    sources.forEach(source => {
      source.forEach(article => {
        if (!mergedArticles[article.supplierNumber]) {
          mergedArticles[article.supplierNumber] = [];
        }
        mergedArticles[article.supplierNumber].push(article);
      });
    });


    const newArticles: Article[] = [];
    let i = 0
    for (const supplierNumber in mergedArticles) {
      i = i + 1
      // console.log(i)
      const supplierArticles = mergedArticles[supplierNumber];

      if (supplierArticles.length === 1) {
        newArticles.push(supplierArticles[0]);
      } else {
        let minPrice = Infinity;
        let minPriceArticle: Article | undefined;
        let totalStock = 0;

        supplierArticles.forEach(article => {
          if (supplierArticles.length === 2 && supplierArticles[0].newPriceEK !== supplierArticles[1].newPriceEK) {
            console.log(article.newStock !== undefined && article.newPriceEK !== undefined)
            console.log(article.newPriceEK)
            console.log(article.newStock)
            console.log("totalstock", totalStock)
            console.log("minprice", minPrice)
            console.log("minpriceArticle", minPriceArticle)
            console.log("supplierArticles", supplierArticles)

          }
          if (article.newStock !== undefined && article.newPriceEK !== undefined) {
            totalStock += article.newStock;
            if (article.newPriceEK < minPrice) {
              minPrice = article.newPriceEK;
              minPriceArticle = article;
            }
          }
        });

        if (totalStock === 0 && minPriceArticle) {
          minPriceArticle.newStock = 0;
          newArticles.push(minPriceArticle);
        } else if (totalStock === 1) {
          newArticles.push(supplierArticles.find(article => article.newStock === 1)!);
        } else {
          let avgPrice = 0;
          // @ts-ignore
          if (minPriceArticle.newStock !== undefined && minPriceArticle.newStock > 5) {
            avgPrice = minPriceArticle?.newPriceEK ?? 0;
          } else {
            avgPrice =
              supplierArticles.reduce((sum, article) => sum + (article.newPriceEK ? article.newPriceEK : 0), 0) / supplierArticles.length;
          }


          // if (!minPriceArticle) {
          //   console.log('#-#-#-#-#-#-----Number', supplierNumber)
          //   console.log('#-#-#-#-#-#-----ARTICLE', supplierArticles)
          // }


          const effectiveStock = totalStock
          minPriceArticle!.newPriceEK = Number(avgPrice.toFixed(2));
          minPriceArticle!.newStock = effectiveStock;
          newArticles.push(minPriceArticle!);
        }
      }
    }
    // console.log(newArticles.slice(0, 100))
    // Include exclusive articles
    sources.forEach(source => {
      source.forEach(article => {
        if (!mergedArticles[article.supplierNumber] && !newArticles.includes(article)) {
          newArticles.push(article);
        }
      });
    });

    console.log(newArticles);

    return newArticles;
  }


  function prepareArrayOfSources3(articlesJarltech: Article[], articlesBlueStar: Article[]) {

    const duplicates: { jarltechArticle: Article, bluestarArticle: Article, supplierNumber: string, priceJarltech?: number, priceBluestar?: number, stockJarltech?: number, stockBluestar?: number }[] = [];

    articlesJarltech.forEach(jarltechArticle => {
      articlesBlueStar.forEach(blueStarArticle => {
        if (jarltechArticle.supplierNumber === blueStarArticle.supplierNumber) {
          duplicates.push({
            supplierNumber: jarltechArticle.supplierNumber,
            priceJarltech: jarltechArticle.newPriceEK,
            priceBluestar: blueStarArticle.newPriceEK,
            stockJarltech: jarltechArticle.newStock,
            stockBluestar: blueStarArticle.newStock,
            jarltechArticle: jarltechArticle,
            bluestarArticle: blueStarArticle
          });
        }
      });
    })

    const duplicateIds = duplicates.map(dup => dup.supplierNumber)
    const newJarltech = articlesJarltech.filter(art => !duplicateIds.includes(art.supplierNumber))
    const newBlueStar = articlesBlueStar.filter(art => !duplicateIds.includes(art.supplierNumber))

    const newDuplicates: Article[] = []
    duplicates.forEach(art => {
      const articleToAdd = art.jarltechArticle
      if (art.priceBluestar && art.priceJarltech && art.stockBluestar && art.stockJarltech) {
        // Falls Jarltech Preis günstiger ist und der Stock für Jarltech größer 2 ist
        if (art.priceJarltech < art.priceBluestar && art.stockJarltech >= 2) {
          // Falls Jarltech Stock größer 5
          if (art.stockJarltech >= 5) {
            articleToAdd.newPriceEK = art.priceJarltech
            articleToAdd.newStock = art.stockJarltech
            // Falls Jarltech Stock zwischen 2 und 4
          } else if (art.stockJarltech <= 4 && art.stockJarltech >= 2) {
            // Durchschnittspreis (gerundet auf 2 Nachkommastellen)
            articleToAdd.newPriceEK = Number(((art.priceJarltech + art.priceBluestar) / 2).toFixed(2))
            articleToAdd.newStock = art.stockJarltech + art.stockBluestar
          }
        } else {
          // Falls Bluestar preis günstiger als Jarltech preis und der Stock größer 5
          if (art.stockBluestar >= 5) {
            articleToAdd.newPriceEK = art.priceBluestar
            articleToAdd.newStock = art.stockBluestar
            // Falls Bluestar stock zwischen 2 und 4
          } else if (art.stockBluestar <= 4 && art.stockBluestar >= 2) {
            articleToAdd.newPriceEK = Number(((art.priceJarltech + art.priceBluestar) / 2).toFixed(2))
            articleToAdd.newStock = art.stockJarltech + art.stockBluestar
          }
        }
      }

      newDuplicates.push(articleToAdd)
    })
    // console.log('DUPLICATES : ', duplicates)
    // console.log('DUPLICATES CALCULATED : ', newDuplicates.filter(art => Number.isNaN(art.newPriceEK)))

    return newBlueStar.concat(newJarltech).concat(newDuplicates)
  }



  // function prepareArrayOfSources2(sources: Article[][]) {
  //   const duplicates: {
  //     supplierNumber: string;
  //     articles: Article[];
  //     prices: number[];
  //     stocks: number[];
  //   }[] = [];

  //   // Find duplicates and gather data from all sources
  //   for (let i = 0; i < sources.length - 1; i++) {
  //     for (let j = i + 1; j < sources.length; j++) {
  //       const sourceA = sources[i];
  //       const sourceB = sources[j];

  //       sourceA.forEach(articleA => {
  //         sourceB.forEach(articleB => {
  //           if (articleA.supplierNumber === articleB.supplierNumber) {
  //             const supplierNumber = articleA.supplierNumber;
  //             const priceA = articleA.newPriceEK;
  //             const priceB = articleB.newPriceEK;
  //             const stockA = articleA.newStock;
  //             const stockB = articleB.newStock;

  //             duplicates.push({
  //               supplierNumber,
  //               articles: [articleA, articleB],
  //               prices: [priceA, priceB],
  //               stocks: [stockA, stockB],
  //             });
  //           }
  //         });
  //       });
  //     }
  //   }

  //   const newSources: Article[][] = [];

  //   // Filter out duplicates and process pricing and stock
  //   sources.forEach(source => {
  //     const newSource: Article[] = [];
  //     source.forEach(article => {
  //       const foundDuplicate = duplicates.find(dup => dup.supplierNumber === article.supplierNumber);
  //       if (!foundDuplicate) {
  //         newSource.push(article);
  //       }
  //     });

  //     newSources.push(newSource);
  //   });

  //   const newDuplicates: Article[] = [];

  //   duplicates.forEach(dup => {
  //     const avgPrice = dup.prices.reduce((total, price) => total + price, 0) / dup.prices.length;
  //     const totalStock = dup.stocks.reduce((total, stock) => total + stock, 0);

  //     if (avgPrice && totalStock) {
  //       const targetArticle = dup.articles[0];
  //       targetArticle.newPriceEK = Number(avgPrice.toFixed(2));
  //       targetArticle.newStock = totalStock;
  //       newDuplicates.push(targetArticle);
  //     }
  //   });

  //   console.log('DUPLICATES: ', duplicates);
  //   console.log('DUPLICATES CALCULATED: ', newDuplicates.filter(art => Number.isNaN(art.newPriceEK)));

  //   return newSources.reduce((result, source) => result.concat(source), newDuplicates);
  // }

  function saveMargens(cats: any, articlesCopy: Article[]) {
    return new Promise((resolve, reject) => {
      setCategories(cats)
      setArticles([])
      setNewPriceForArticle2(articlesCopy, cats).then(res => {
        if (csvDroped !== undefined) {
          setMargenViewOpen(false)
          resolve(articlesCopy)
        } else {
          //console.log("CSV INVALID");
        }
      })
    })
  }

  // function handleOnMargeDrop(_csv: any) {
  //   const cats = importCategories(_csv)
  //   setCategories(cats)
  //   setArticles([])
  //   let articlesCopy = matchingArticles

  //   setNewPriceForArticle(articlesCopy.slice(0, articlesCopy.length / 2), cats).then(res => {
  //     console.log(res)
  //     if (csv !== undefined) {

  //       handleOnBestandDrop(csv)

  //     } else {
  //       console.log("CSV INVALID");
  //     }
  //   })
  // }

  function handleOnError(err: any, file: any, inputElem: any, reason: any) {
    console.log(err)
  }

  function loadCategories() {
    getCategories().then(res => {
      JSONToCSVConvertor(res, '', true, 'Kategorien')
    })
  }


  function getMatchingArticles() {
    JSONToCSVConvertor(matchingArticles, '', true, 'MatchingArticles')
  }
  function getDifferentPrice() {
    JSONToCSVConvertor(differentPrice, '', true, 'DifferentPrice')
  }
  function getMissingInCSV() {
    JSONToCSVConvertor(missingInCSV, '', true, 'MissingInCSV')
  }
  function getMissinginShop() {
    JSONToCSVConvertor(missinginShop, '', true, 'MissingInShop')
  }

  function handleOnRemoveFile(data: any) {
    //
  }

  function login(): void {
    if (username === 'acventis' && psw === 'acventisPSW') {
      setLoginError(false)
      setLoggedIN(true)
    } else {
      setLoginError(true)
    }
  }

  async function startSync(ek: boolean, margen: boolean, promo: boolean, outlist: boolean) {

    let articleToWorkWith = matchingArticles
    if (promo) {
      const loadedPromo = await getPromoData()
      loadedPromo.forEach(promo => {

        let endDate = moment(promo.endDate, 'DD.MM.YYYY')
        if (moment().isSameOrBefore(endDate) && promo.status) {
          let promoArticle = articleToWorkWith.find(art => art.supplierNumber === promo.supplierNumber)
          if (promoArticle) {
            promoArticle.endDate = promo.endDate
            promoArticle.status = promo.status
            promoArticle.newPriceEK = promo.newPriceEK
            promoArticle.pricePromo = promo.pricePromo
            articleToWorkWith = articleToWorkWith.filter(art => art.supplierNumber !== promo.supplierNumber)
            articleToWorkWith.push(promoArticle)
          }
        }
      })
    }

    if (ek) {
      articleToWorkWith = await updateArticle(articleToWorkWith)
    }

    if (margen) {
      let loadedMargen = await loadMargen()
      let catsCopy = loadedCategories
      catsCopy?.forEach(cat => {
        if (loadedMargen[cat.name] !== undefined) {
          cat.marge = Number(loadedMargen[cat.name])
        }
      })
      saveMargens(catsCopy, articleToWorkWith)
    }

    if (outlist) {

      const pushList: Promise<any>[] = [];
      missingInCSV.filter((item) => supplierList.map((i) => i.id).includes(Number(item.supplierID))).map((item) => item.incrID).forEach((data) => {
        //remove data === 17145
        if (data !== undefined && data === 17145) {
          pushList.push(moveToOutListArticle(data));
        }
      })

      await Promise.all(pushList);
    }

    await updateLastSync();
    await getLastSync().then((res) => setLastSync(res));

    // restart everything
    setUpdatePopUpOpen(false)
    if (csvDroped !== undefined) {
      handleOnBestandDrop(csvDroped)
    } else {
      console.log("CSV INVALID");
    }
  }

  async function updateArticle(articleToWorkWith: Article[]): Promise<Article[]> {

    return new Promise((resolve, reject) => {
      const articleToUpdate = articleToWorkWith
      // setEkAndStockProgress(true)
      // const articleToUpdate = matchingArticles.filter(article => article.incrID === 18824)

      updateStockForAllArticle(articleToUpdate).then(async res => {
        setEkAndStockProgress(false)
        resolve(articleToUpdate)
      }).catch(err => {
        reject(err)
        alert('Ein Fehler ist aufgetreten. Lade die Seite neu!')
      }
      )
    })

  }

  function finishEndOFLife() {
    setNotPresentView(false)
    handleOnBestandDrop(csvDroped)
    setNotPresentView(true)
  }

  function getColorForLoadingState(supplier: "shop" | "ingram" | "blueStar" | "jarltech") {
    let state: LoadState = supplierLoadedState[supplier]
    switch (state) {
      case LoadState.LOADING:
        console.log("return gray")
        return "gray"
      case LoadState.LOADED:
        return "green"
      case LoadState.CANCELED:
        return "red"
      case LoadState.NEUTRAL:
        return "#fff"
      default:
        return "blue"
    }
  }

  return (

    < div className="App" style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      {loggedIN && !margenViewOpen && !notPresentView && !promoView && !suppliersView &&
          <>
            <div style={{width: '80%', marginTop: 80}}>
              <div style={{display: "flex", justifyContent: "space-between"}}>
                <div style={{textAlign: 'left', fontSize: 15, fontWeight: "bold", textDecoration: "underline"}}>
                  Last Sync: {moment(lastSync).format("DD.MM.YYYY HH:mm:ss")}
                </div>
                <div style={{textAlign: 'right', fontSize: 15, fontWeight: "bold", textDecoration: "underline"}}>
                  Last Sync Auto: {moment(lastSyncOnline).format("DD.MM.YYYY HH:mm:ss")}
                </div>
              </div>
              {/* <div style={{ height: 60, width: 120, display: 'flex', justifyContent: 'center', alignItems: 'center', border:"#000 1px solid" }} onClick={ loadShopArticles}>
              Nur Shop-Artikel laden
            </div> */}
              Bitte ziehe die Bestand und EK Excel in das Feld unten
              <div style={{marginTop: 20, height: 100}}>

                < CSVReader
                    onDrop={handleOnBestandDrop}
                    onError={handleOnError}
                    addRemoveButton
                    removeButtonColor='#659cef'
                    onRemoveFile={handleOnRemoveFile}
                >
                  <span>Bestand und EK</span>
                </CSVReader>

                {progress &&
                    <div>
                      <div style={{fontSize: 18, fontWeight: 'bold'}}>LÄDT... Bitte warten, bis die Anzahl der geladenen
                        Artikel angezeigt wird <p>Dies kann einige Sekunden dauern.</p></div>
                      <div style={{fontSize: 12, display: "flex", flexDirection: "row", alignItems: 'baseline'}}>
                        <p>SHOP: {supplierLoadedState.shop}</p>
                        <div style={{
                          borderRadius: 60,
                          height: 10,
                          width: 10,
                          border: "#000 1px solid",
                          backgroundColor: getColorForLoadingState("shop")
                        }}></div>
                      </div>
                      <div style={{
                        fontSize: 12,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: 'baseline'
                      }}>BLUESTAR: <p>{supplierLoadedState.blueStar}</p>
                        <div style={{
                          borderRadius: 60,
                          height: 10,
                          width: 10,
                          border: "#000 1px solid",
                          backgroundColor: getColorForLoadingState("blueStar")
                        }}></div>
                      </div>
                      <div style={{
                        fontSize: 12,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: 'baseline'
                      }}>INGRAM: <p>{supplierLoadedState.ingram}</p>
                        <div style={{
                          height: 10,
                          width: 10,
                          borderRadius: 60,
                          border: "#000 1px solid",
                          backgroundColor: getColorForLoadingState("ingram")
                        }}></div>
                      </div>

                    </div>
                }
                {articles.length !== 0 &&
                    <div style={{fontSize: 18, fontWeight: 'bold'}}>{articles.length} Artikel
                      geladen <br></br> {matchingArticles.length} Artikel aus CSV gefunden</div>
                }
                {margenProgress &&
                    <div>Margen werden aktualisiert. Lädt..</div>
                }
              </div>

            </div>

            {articles.length !== 0 &&
                <div style={{width: '80%', marginTop: 150,}}>
                  <div style={{
                    marginTop: 20,
                    height: 100,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                    <div onClick={() => setMargenViewOpen(true)} style={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '#000 dashed 1px',
                      cursor: 'pointer',
                    }}>
                      <div>Margen bearbeiten</div>
                    </div>
                    <div onClick={() => setNotPresentView(true)} style={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '#000 dashed 1px',
                      cursor: 'pointer',
                    }}>
                      <div>End Of Life</div>
                    </div>
                    <div onClick={() => setPromoView(true)} style={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '#000 dashed 1px',
                      cursor: 'pointer',
                    }}>
                      <div>Sonderpreise</div>
                    </div>
                    <div onClick={() => setSuppliersView(true)} style={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '#000 dashed 1px',
                      cursor: 'pointer',
                    }}>
                      <div>Suppliers</div>
                    </div>
                  </div>
                </div>
            }

            <div style={{
              width: '80%',
              height: 80,
              marginTop: 150,
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-between'
            }}>

              {!progress && articles.length !== 0 &&
                  <>
                    <div style={{
                      width: '33%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderStyle: 'solid',
                      borderWidth: 2,
                      borderColor: '#000',
                      cursor: 'grab',
                    }}
                         onClick={() => {
                           setAutoPopupOpen(true)
                         }}>{ekAndStockProgress ? 'Lädt..' : 'Configure Auto Sync'}</div>
                    <div style={{
                      width: '33%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderStyle: 'solid',
                      borderWidth: 2,
                      borderColor: '#000',
                      cursor: 'grab',
                    }}
                         onClick={() => {
                           setUpdatePopUpOpen(true)
                         }}>{ekAndStockProgress ? 'Lädt..' : 'Synchronisierung'}</div>
                  </>


              }
              <div>{ekAndStockProgress ? 'Lädt..' : ''}</div>
              <div style={{
                width: '33%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderStyle: 'solid',
                borderWidth: 2,
                borderColor: '#000',
                cursor: 'grab',
              }}
                   onClick={() => {
                     loadCategories()
                   }}>Kategorien Vorlage herunterladen
              </div>
            </div>
            {
                articles.length !== 0 &&
                < div style={{
                  width: '80%',
                  height: 80,
                  marginTop: 50,
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}>
                  <div style={{
                    width: '15%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderStyle: 'solid',
                    borderWidth: 2,
                    borderColor: '#000',
                    cursor: 'grab',
                  }}
                       onClick={() => {
                         getMatchingArticles()
                       }}>In CSV und Shop vorhanden
                  </div>

                  <div style={{
                    width: '15%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderStyle: 'solid',
                    borderWidth: 2,
                    borderColor: '#000',
                    cursor: 'grab',
                  }}
                       onClick={() => {
                         getDifferentPrice()
                       }}>Unterschiedliche Preise
                  </div>

                  <div style={{
                    width: '15%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderStyle: 'solid',
                    borderWidth: 2,
                    borderColor: '#000',
                    cursor: 'grab',
                  }}
                       onClick={() => {
                         getMissingInCSV()
                       }}>In Shop aber nicht in CSV
                  </div>

                  <div style={{
                    width: '15%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderStyle: 'solid',
                    borderWidth: 2,
                    borderColor: '#000',
                    cursor: 'grab',
                  }}
                       onClick={() => {
                         getMissinginShop()
                       }}>In CSV aber nicht in Shop
                  </div>
                </div>
            }

            <div style={{
              width: '15%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderStyle: 'solid',
              borderWidth: 2,
              borderColor: '#000',
              cursor: 'grab',
              marginTop: 10
            }}
                 onClick={() => {
                   setShowCategoryBox(true)
                 }}>Series System
            </div>

            <div>
              {progress}
            </div>
          </>
      }
      {
          margenViewOpen && loggedIN && loadedCategories &&
          < div style={{marginTop: 80, height: '100vh'}}>
            <Button onClick={() => setMargenViewOpen(false)} style={{position: 'absolute', left: 12, top: 12}}
                    variant="contained">
            <LeftIcon style={{ color: '#fff' }} />
            Zurück
          </Button>
          <CategorySelectBox saveMargens={(cats) => saveMargens(cats, matchingArticles)} categories={loadedCategories} onChange={() => { }} multiple={true} />
        </div>
      }

      {showCategoryBox && (
          < div style={{ marginTop: 0, position: 'absolute', top:0, height: '100vh', background: "#fff", width: "100%" }}>
            <Button onClick={() => setShowCategoryBox(false)} style={{ position: 'absolute', left: 12, top: 10 }} variant="contained">
              <LeftIcon style={{ color: '#fff' }} />
              Zurück
            </Button>
            {loadedCategories && (
                <CategoryAttributes categories={loadedCategories ?? []} />
            )}
          </div>
      )}

      {
        notPresentView && loggedIN && loadedCategories && articles && (
          <NotPresentInCSV finish={finishEndOFLife} setNotPresentView={setNotPresentView} categories={loadedCategories} missingInCSV={missingInCSV} />
        )
      }
      {
        promoView && loggedIN && loadedCategories && articles && (
          <PromoPage categories={loadedCategories} close={() => setPromoView(false)} articles={matchingArticles} />
        )
      }
      {
        suppliersView && loggedIN && loadedSuppliers && (
          <SuppliersPage setSupplierList={setSupplierList} supplierList={supplierList} suppliers={loadedSuppliers} close={() => setSuppliersView(false)} articles={matchingArticles} />
        )
      }
      {updatePopUpOpen &&
        <UpdatePopUp safe={startSync} close={() => setUpdatePopUpOpen(false)} matchingArticle={matchingArticles} />
      }
      {autoPopupOpen &&
          <AutoSyncPopup close={() => setAutoPopupOpen(false)} />
      }

      {
        !loggedIN &&
        <Login setlogin={setLoggedIN} />
      }
    </div >

  );
}

export default App;



