import axios from "axios"
import { category, isArticleUnderCat, supplier } from "./category"
import { environment } from '../environment'
import { createTaggedTemplate } from "typescript"
import moment from "moment/moment";
export type Article = {
    supplierNumber: string,
    name: string,
    price: number,
    incrID?: number,
    stock: number,
    priceEK: number,
    newStock?: number,
    newPriceEK?: number,
    pricePromo?: number | undefined
    newPrice?: number,
    marge?: number,
    category: number[],
    subCategory: number[],
    attr8: any
    categories?: string,
    status?: boolean,
    endDate?: string,
    supplierID: number;
}


export function getAllData(): Promise<any> {
    return new Promise((resolve, reject) => {
        const url = environment.ownBackendURL + 'dataget'
        axios.get(url).then(res => {
            resolve(res.data);
        })
    })
}


export function updateConfig(body: {
    ek: 1 | 0,
    margen: 1 | 0,
    promo: 1 | 0,
    outlist: 1 | 0,
    percentage: number
}): Promise<any> {
    return new Promise((resolve, reject) => {
        const url = environment.ownBackendURL + 'updateConfig'
        axios.put(url, body).then(res => {
            resolve(res.data);
        })
    })
}


export function getConfig(): Promise<any> {
    return new Promise((resolve, reject) => {
        const url = environment.ownBackendURL + 'config'
        axios.get(url).then(res => {
            resolve(res.data);
        })
    })
}

export function getArticles(url: string): Promise<Article[]> {
    return new Promise((resolve, reject) => {
        axios.get(url, { auth: { password: 'wit58vLrcqH5sN4uVOFgTII4cMQlzgRblT2tpArz', username: 'kemal' } })
            .then(async res => {
                // console.log('TEEEEESSSTSTTTT GGAGAGAGGA', res.data.data.find((art: any) => art.mainDetail.supplierNumber === '08512.445-0020'))
                resolve(res.data.data
                    .map((article: any) => {
                        try {
                            let price = article.mainDetail.prices[0]?.price !== undefined ? article.mainDetail.prices[0]?.price : undefined
                            let subCategory = article.categories[0] !== undefined && article.categories.length !== 0 ? article.categories.map((cat: any) => cat.id) : []
                            let cat = (article.categories[0] !== undefined && article.categories.length !== 0) ? article.categories.map((cats: any) => cats.parentId) : []
                            return { name: article.name, supplierNumber: `${article.mainDetail.supplierNumber}`.trim(), priceEK: Number(article.mainDetail.purchasePrice), incrID: article.id, stock: article.mainDetail.inStock, price: price, subCategory: subCategory, category: cat, attr8: article.mainDetail?.attribute?.attr8, supplierID: article.supplierId }
                        } catch (err) {
                            console.log(err);
                        }
                    }))
            })
            .catch(err => reject())
    })
}

export function updateArticle(id: number, stock?: number, priceEK?: number, price?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
        const url = environment.backendURL + 'articles/' + id
        let body: any = { mainDetail: {} }
        if (stock !== undefined) {
            body.mainDetail.inStock = stock
        }
        if (priceEK !== undefined) {
            body.mainDetail.purchasePrice = priceEK
        }
        if (price !== undefined) {
            body.mainDetail.prices = [{ price: price }]
        }
        axios.put(url, body, { auth: { password: 'wit58vLrcqH5sN4uVOFgTII4cMQlzgRblT2tpArz', username: 'kemal' } })
            .then(res => { resolve(true) })
            .catch(err => console.log('err', err))
    })
}

export function moveToAskForPrice(id: number, category: number[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
        const url = environment.backendURL + 'articles/' + id

        const data = {
            "categories": [...category.map((i) => {
                return { id: i }
            }), {
                "id": 100
            }],
            "mainDetail": {
                "attribute": {
                    "attr8": moment().subtract(10, 'day').toDate()
                },
                "prices": [
                    { "pseudoPrice": 0 }
                ]
            }
        }
        axios.put(url, data, { auth: { password: 'wit58vLrcqH5sN4uVOFgTII4cMQlzgRblT2tpArz', username: 'kemal' } })
            .then(res => { resolve(true) })
            .catch(err => console.log('err', err))
    })
}


export function moveToOutListArticle(id: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
        const url = environment.backendURL + 'articles/' + id

        const data = {
            "mainDetail": {
                "inStock": 0,
                "attribute": {
                    "outlisted": 1
                }
            }
        }
        axios.put(url, data, { auth: { password: 'wit58vLrcqH5sN4uVOFgTII4cMQlzgRblT2tpArz', username: 'kemal' } })
            .then(res => { resolve(true) })
            .catch(err => console.log('err', err))
    })
}

export function updateStockForAllArticle(articlesToUpdate: Article[]): Promise<any> {
    let promisses: Promise<any>[] = []

    articlesToUpdate.forEach(article => {
        if (article.incrID) {
            if (article.stock !== article.newStock || article.priceEK !== article.newPriceEK) {
                if (article.newStock && article.newPriceEK) {
                    article.stock = article.newStock
                    article.priceEK = article.newPriceEK
                }
                promisses.push(
                    updateArticle(article.incrID, article.newStock, article.newPriceEK)
                )
            }
        } else {
        }
    }
    )
    console.log('changedArticle:', promisses.length);
    return Promise.all(promisses)
}

export function createArticle() {
    const url = environment.backendURL + 'articles'

    axios.put(url, {
        name: 'testArtikel', taxId: 1, mainDetail: {
            number: '123567jkbhjkv', prices: [
                {
                    price: 999
                }
            ]
        }, supplierId: 5
    }, { auth: { password: 'wit58vLrcqH5sN4uVOFgTII4cMQlzgRblT2tpArz', username: 'kemal' } })
        .then(res => { console.log(res) })
        .catch(err => console.log('err', err))
}

export function getPromoData(): Promise<Article[]> {
    return new Promise((resolve, reject) => {
        axios.get(environment.ownBackendURL + 'promo').then(res => {
            resolve(JSON.parse(JSON.stringify(res.data)))
        })
    })
}


export function getSupplierData(): Promise<supplier[]> {
    return new Promise((resolve, reject) => {
        axios.get(environment.ownBackendURL + 'savedSuppliers').then(res => {
            resolve(JSON.parse(JSON.stringify(res.data)))
        })
    })
}

export function getLastSync(): Promise<string> {
    return new Promise((resolve, reject) => {
        axios.get(environment.ownBackendURL + 'lastSync').then(res => {
            resolve(res.data)
        })
    })
}

export function lastSyncAuto(): Promise<string> {
    return new Promise((resolve, reject) => {
        axios.get(environment.ownBackendURL + 'lastSyncAuto').then(res => {
            resolve(res.data)
        })
    })
}

export function updateLastSync(): Promise<string> {
    return new Promise((resolve, reject) => {
        axios.put(environment.ownBackendURL + 'lastSync').then(res => {
            resolve(res.data)
        })
    })
}

export function calculateArticle(): Promise<Article[]> {
    return new Promise<Article[]>((resolve, reject) => {

        let urls = [environment.backendURL + 'articles',
        environment.backendURL + 'articles?start=1001',
        environment.backendURL + 'articles?start=2001',
        environment.backendURL + 'articles?start=3001',
        environment.backendURL + 'articles?start=4001',
        environment.backendURL + 'articles?start=5001'
        ]

        let loadedArticle: Article[] = [];
        let requests: { id: number, requested: number }[] = []
        let wrongrequests: Article[] = []

        urls.forEach(async url => {
            fillData(url, requests, wrongrequests).then(async (res: Article[]) => {
                loadedArticle = loadedArticle.concat(res)
                console.log('finished', loadedArticle.length);
                if (loadedArticle.length >= 3095) {
                    resolve(loadedArticle)
                }
            })
        })
    })
}

export function fillData(url: string, requests: { id: number, requested: number }[], wrongrequests: Article[]): Promise<Article[]> {
    return new Promise(async (resolve, reject) => {
        getArticles(url).then((articles: Article[]) => {
            resolve(articles)
        })
    })
}

export function setNewPriceForArticle2(articles: Article[], categories: category[]): Promise<any[]> {
    const rootID: number | undefined = categories.find(_cat => _cat.name === "Root")?.id

    articles.forEach(article => {
        let allDirectSubcats: number[] = article.subCategory
        let foundMargen: number[] = [];
        let currentID = allDirectSubcats.length !== 0 ? allDirectSubcats[0] : 0;
        let finished = false;
        if (currentID === 0) {
            return
        } else {
            allDirectSubcats = allDirectSubcats.filter(subID => subID !== currentID)
        }

        while (foundMargen.length !== article.subCategory.length && finished !== true) {
            if (currentID === rootID) {
                currentID = allDirectSubcats.length !== 0 ? allDirectSubcats[0] : 0;
            }
            if (currentID === 0) {
                finished = true
            } else {
                allDirectSubcats = allDirectSubcats.filter(subID => subID !== currentID)
            }

            let foundCat = categories.find(cat => cat.id === currentID)
            if (foundCat && (foundCat.marge !== undefined && foundCat.marge !== '' && foundCat.marge !== 0 && foundCat.marge !== '0')) {
                foundMargen.push(Number(foundCat.marge))
                currentID = allDirectSubcats.length !== 0 ? allDirectSubcats[0] : 0;
            } else {
                if (foundCat) {
                    currentID = foundCat.parentId
                } else {
                    finished = true
                }
            }
        }
        if (foundMargen.length !== 0) {

            let marge = sortBySize(foundMargen)[0]
            if (article.newPriceEK) {

                let newPrice = (article.newPriceEK * ((Number(marge) / 100) + 1)).toFixed(2)
                let afterComma = newPrice.split('.')
                let firstNumber = afterComma.length === 2 ? Number(afterComma[1].slice(0, 1)) : 0
                let lastNumber = afterComma.length === 2 ? Number(afterComma[1].slice(1, 2)) : 0
                let roundedAfterComma;
                if (lastNumber === 0) {
                    roundedAfterComma = 0
                }
                else if (lastNumber <= 4) {
                    roundedAfterComma = 5
                } else if (lastNumber >= 5 && firstNumber !== 9) {
                    roundedAfterComma = 0
                    firstNumber = firstNumber + 1
                    // if()
                } else if (lastNumber >= 5 && firstNumber === 9) {
                    roundedAfterComma = 0
                    firstNumber = 0
                    afterComma[0] = `${Number(afterComma[0]) + 1}`
                } else if (lastNumber === 9 && firstNumber !== 9) {
                    roundedAfterComma = 0
                    firstNumber = firstNumber + 1
                }

                let roundedValue = Number(`${afterComma[0]}.` + `${firstNumber}${roundedAfterComma}`)
                article.newPrice = article.pricePromo ? article.pricePromo : roundedValue
            }
        }
        return true
    })

    let promisses: Promise<boolean>[] = []
    articles.forEach(art => {
        if (art.incrID) {
            if (`${art.price}` !== `${art.newPrice}`) {
                console.log('From', art.price, 'to', art.newPrice);
                promisses.push(updateArticle(art.incrID, undefined, undefined, art.newPrice))
            } else {
                promisses.push(new Promise((resolve, reject) => {
                    resolve(true)
                })
                )
            }
        }
    })
    return Promise.all(promisses)

}
// export function setNewPriceForArticle(articles: Article[], categories: category[]): Promise<any[]> {

//     let foundArticles: Article[] = [];
//     categories.forEach(cat => {
//         if (cat.marge !== 0 && cat.marge !== '' && cat.marge !== undefined) {
//             let foundArticlesForCat: any[] = [];
//             try {
//                 foundArticlesForCat = articles.filter(art => {
//                     return isArticleUnderCat(art, cat, categories)
//                 })
//             } catch (err) {
//                 console.log(err)
//             }

//             foundArticlesForCat.forEach(article => {
//                 if (article.priceEK) {

//                     let newPrice = (article.priceEK * ((Number(cat.marge) / 100) + 1)).toFixed(2)
//                     let afterComma = newPrice.split('.')
//                     let firstNumber = afterComma.length === 2 ? Number(afterComma[1].slice(0, 1)) : 0
//                     let lastNumber = afterComma.length === 2 ? Number(afterComma[1].slice(1, 2)) : 0
//                     let roundedAfterComma;
//                     if (lastNumber === 0) {
//                         roundedAfterComma = 0
//                     }
//                     else if (lastNumber <= 4) {
//                         roundedAfterComma = 5
//                     } else if (lastNumber >= 5 && firstNumber !== 9) {
//                         roundedAfterComma = 0
//                         firstNumber = firstNumber + 1
//                         // if()
//                     } else if (lastNumber >= 5 && firstNumber === 9) {
//                         roundedAfterComma = 0
//                         firstNumber = 0
//                         afterComma[0] = `${Number(afterComma[0]) + 1}`
//                     } else if (lastNumber === 9 && firstNumber !== 9) {
//                         roundedAfterComma = 0
//                         firstNumber = firstNumber + 1
//                     }

//                     let roundedValue = Number(`${afterComma[0]}.` + `${firstNumber}${roundedAfterComma}`)
//                     article.newPrice = roundedValue

//                     foundArticles.push(article)
//                 }
//             })
//         }
//     })
//     let uniqueArray = arrUnique(foundArticles)
//     let promisses: Promise<boolean>[] = []
//     uniqueArray.forEach(art => {
//         if (art.incrID) {
//             if (`${art.price}` !== `${art.newPrice}`) {
//                 console.log('From', art.price, 'to', art.newPrice);
//                 promisses.push(updateArticle(art.incrID, undefined, undefined, art.newPrice))
//             }
//         }
//     })
//     console.log('Changed Prices', promisses.length);
//     return Promise.all(promisses)
// }

function arrUnique(array: Article[]) {

    let cleanedArrayIDs: number[] = []
    let cleanedArray: Article[] = []
    array.forEach(art => {
        if (art.incrID) {
            if (cleanedArrayIDs.includes(art.incrID)) {
            } else {
                cleanedArrayIDs.push(art.incrID)
                cleanedArray.push(art)
            }
        }
    })

    return cleanedArray

}

function sortBySize(arr: number[]) {

    arr.sort(function (a, b) {
        return a - b;
    });

    return arr
}












// export function fillPriceDetails(articles: any, requests: { id: number, requested: number }[], wrongrequests: Article[]): Promise<{ requests: { id: number, requested: number }[], wrongrequests: Article[] } | boolean> {
//     return new Promise<{ requests: { id: number, requested: number }[], wrongrequests: Article[] } | boolean>((resolve, reject) => {
//         let invalidPriceArticles = []
//         const promisses: any[] = []
//         if (articles.length === 0) {
//             resolve(true)
//         }

//         articles.forEach((article: Article) => {
//             let request = requests.find(request => request.id === article.incrID)
//             if (request) {
//                 requests.forEach(request => {
//                     if (request.id === article.incrID) {
//                         request.requested = request.requested + 1
//                     }
//                 })
//             } else {
//                 if (article.incrID) {
//                     requests.push({ id: article.incrID, requested: 1 })
//                 }
//             }

//             let addedRequest = requests.find(request => request.id === article.incrID)
//             if (article.incrID) {
//                 promisses.push(
//                     getArticlePrice(article.incrID)
//                         .then((data: any) => {
//                             try {
//                                 article.price = data.price
//                                 article.category = data.category
//                                 article.subCategory = data.subCategory
//                             } catch (err) {
//                                 console.log(err)
//                             }
//                         }).catch(err => {
//                             if (addedRequest) {
//                                 if (addedRequest.requested <= 4) {
//                                     articles.forEach(async (_article: any) => {
//                                         if (_article.incrID === article.incrID) {
//                                             try {
//                                                 // let a = await fillPriceDetails([_article])
//                                             } catch (err) {

//                                             }
//                                         }
//                                     })
//                                 } else {
//                                     wrongrequests.push(article)
//                                 }
//                             }
//                         })
//                 )
//             }
//         })

//         return Promise.all(promisses).then(res => {
//             resolve({ wrongrequests, requests })
//         }).catch((article) => console.log('######', article))
//     })
// }


// export function getArticlePrice(id: number): Promise<{ price: number | undefined, subCategory: number | undefined, category: number | undefined }> {
//     return new Promise((resolve, reject) => {
//         const url = environment.backendURL + 'articles/' + id
//         axios.get(url, { auth: { password: 'wit58vLrcqH5sN4uVOFgTII4cMQlzgRblT2tpArz', username: 'kemal' } })
//             .then((res: any) => {
//                 let data: { price: number | undefined, subCategory: number | undefined, category: number | undefined } = { price: undefined, subCategory: undefined, category: undefined }

//                 if (res.data.data.mainDetail.prices[0] === undefined) {
//                     data.price = undefined
//                 } else {
//                     data.price = res.data.data.mainDetail.prices[0]?.price
//                 }
//                 if (res.data.data.categories[0] === undefined) {
//                     data.subCategory = 0
//                 } else {
//                     data.subCategory = res.data.data.categories[0]?.id
//                 }
//                 if (res.data.data.seoCategories[0]?.category.parentId === undefined) {
//                     data.category = 0
//                 } else {
//                     if (data.subCategory === undefined) {
//                         data.subCategory = res.data.data.seoCategories[0]?.category.id
//                     }
//                     data.category = res.data.data.seoCategories[0]?.category.parentId
//                 }
//                 resolve(data)
//             }).catch(err => {
//                 // console.log(err)
//                 reject(err)
//             })
//     })
// }
