import logo from './logo.svg';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { CSVReader } from 'react-papaparse'
import React from 'react';
import { Article } from './article';

function generateCSVData(csv: any) {
    const csvData = csv.filter((art: any) => art.data.length !== 1).map((art: any) => art.data)
    let supplierName = csvData[0].indexOf('MANUFACTURER')
    let supplierNumberIndex = csvData[0].indexOf('ORIGINAL_ART_NO')
    let priceEKIndex = csvData[0].indexOf('YOUR_PRICE_NET_PER_PACKING_UNIT')
    let stockIndex = csvData[0].indexOf('STOCK_QTY')

    let allCSVArticles: Article[] = csvData.map((article: any) => {
        let supplierIsMOBILIS = `${article[supplierName]}`.toLowerCase() === 'mobilis'
        let supplierNumber = `${supplierIsMOBILIS === false ? article[supplierNumberIndex] : `0${article[supplierNumberIndex]}`}`
        return { supplierNumber: supplierNumber, priceEK: Number(article[priceEKIndex]?.replace(/,/g, '.')), incrID: undefined, stock: Number(article[stockIndex]?.replace(/,/g, '.')) }
    })
    allCSVArticles = allCSVArticles.filter(art => art.supplierNumber !== '')
    return allCSVArticles
}

export function getShopArticleWhichAreNotInCSV(csv: any, loadedArticles: Article[]): Article[] {
    let allCSVArticles: Article[] = generateCSVData(csv)

    let missingArticles: Article[] = []
    loadedArticles.map(article => article.supplierNumber).forEach((articleID) => {
        const csvIds = allCSVArticles.map(article => article.supplierNumber)
        if (!csvIds.includes(articleID)) {
            const article = loadedArticles.find(article => article.supplierNumber === articleID)
            if (article) {
                missingArticles.push(article)
            }
        }
    })
    return missingArticles
}

export function getCSVArticleWhichAreNotInShop(csv: any, loadedArticles: Article[]): Article[] {
    let allCSVArticles: Article[] = generateCSVData(csv)
    let missingArticles: Article[] = []

    let index = 0
    allCSVArticles.map((article: Article) => article.supplierNumber).forEach(articleID => {
        const shopIDs = loadedArticles.map(article => article.supplierNumber)
        if (!shopIDs.includes(articleID)) {
            const article = allCSVArticles.find(article => article.supplierNumber === articleID)
            if (article) {
                missingArticles.push(article)
            } else {
            }
        } else {
            index += 1
        }
    })
    return missingArticles
}

export function getIDMatchingArticles(csv: any, loadedArticles: Article[], blueStarArticle?: Article[]): Promise<Article[]> {
    return new Promise((resolve, reject) => {

        let allCSVArticles: Article[] = blueStarArticle ? blueStarArticle : generateCSVData(csv)
        let sameArticles: Article[] = []

        console.log(allCSVArticles)

        loadedArticles.map(article => article.supplierNumber).forEach((articleID) => {
            const csvIds = allCSVArticles.map(article => article.supplierNumber)
            if (csvIds.includes(articleID)) {
                let articleInShop = loadedArticles.filter(article => article.supplierNumber === articleID).map(art => { return { ...art } })
                let articleInCSV = allCSVArticles.find(article => article.supplierNumber === articleID)
                if (articleInShop.length !== 0 && articleInCSV) {
                    articleInShop[0].newStock = Number(articleInCSV.stock)
                    articleInShop[0].newPriceEK = Number(articleInCSV.priceEK)
                    sameArticles.push(articleInShop[0])
                }
            }
        })
        resolve(sameArticles)
    })
}

export function getDifferntPriceArticle(csv: any, loadedArticles: Article[], allArticle?: Article): { HerstellerNr: string, csvPrice: number, shopPrice: number }[] {

    const csvData = csv.filter((art: any) => art.data.length !== 1).map((art: any) => art.data)

    let supplierName = csvData[0].indexOf('MANUFACTURER')
    let supplierNumberIndex = csvData[0].indexOf('ORIGINAL_ART_NO')
    let priceEKIndex = csvData[0].indexOf('YOUR_PRICE_NET_PER_PACKING_UNIT')
    let stockIndex = csvData[0].indexOf('STOCK_QTY')

    const notSamePrice: { HerstellerNr: string, csvPrice: number, shopPrice: number }[] = []

    loadedArticles.forEach(loadedArt => {
        const rightArticle = csvData.find((article: any) => {
            let supplierIsMOBILIS = `${article[supplierName]}`.toLowerCase() === 'mobilis'
            let supplierNumber = `${supplierIsMOBILIS === false ? article[supplierNumberIndex] : `0${article[supplierNumberIndex]}`}`
            return supplierNumber === loadedArt.supplierNumber
        })

        if (rightArticle) {
            if (Number(rightArticle[priceEKIndex].replace(/,/g, '.')) !== Number(loadedArt.priceEK)) {
                notSamePrice.push({ HerstellerNr: loadedArt.supplierNumber, csvPrice: Number(rightArticle[priceEKIndex].replace(/,/g, '.')), shopPrice: Number(loadedArt.price) })
            }
        }
    })
    return notSamePrice
}

export function getDuplicatedArticlesInList(articles: Article[]): Article[] {
    const duplicateInList: Article[] = []
    let index = 0
    let articleIDs = articles.map(article => {
        index += 1;
        return { id: article.supplierNumber, index }
    })
    articleIDs.forEach(article => {
        let allArticlesExceptThisOne = articleIDs.filter(_article => _article.id !== article.id && _article.index !== article.index)
        if (allArticlesExceptThisOne.map(__article => __article.id).includes(article.id)) {
            const duplicateArticle = articles.find(art => art.supplierNumber === article.id)
            if (duplicateArticle) {
                duplicateInList.push(duplicateArticle)
            }
        }
    })
    return duplicateInList
}

export function JSONToCSVConvertor(JSONData: any, ReportTitle: any, ShowLabel: boolean, _fileName: string) {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData =
        typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;
    var CSV = "";


    //This condition will generate the Label/Header
    if (ShowLabel) {
        var row = "";
        //This loop will extract the label from 1st index of on array
        for (var index in arrData[0]) {
            //Now convert each value to string and comma-seprated
            row += index + ";";
        }

        row = row.slice(0, -1);

        //append Label row with line break
        CSV += row + "\r\n";
    }


    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
        var row = "";

        //2nd loop will extract each column and convert it in string comma-seprated
        for (var index in arrData[i]) {
            row += '"' + arrData[i][index] + '";';
        }

        row.slice(0, row.length - 1);

        //add a line break after each row
        CSV += row + "\r\n";
    }

    if (CSV === "") {
        alert("Invalid data");
        return;
    }

    //Generate a file name
    var fileName = _fileName;
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link: any = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
