import {category} from "../../repository/category";

import React, {useCallback, useEffect, useState} from "react";
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import {Box} from "@mui/material";
import {Product} from "./TabList";


interface CategoryBoxInterface {
    categories: category[];
    setProductData: React.Dispatch<React.SetStateAction<Product>>
    productData: Product;
}

export default function CategoryBox(props: CategoryBoxInterface){

    const {categories} = props;

    const categoryParser = useCallback((category: category) => {

        const subCount = category.childrenCount;

        if(subCount === 0){
            return <TreeItem ContentProps={{style: {textAlign: "left"}, onClick: () => {
                  props.setProductData((prev) => {
                      if(!prev.categories.find((i) => i.id === category.id)){
                          return {...prev, categories: [...prev.categories, {id: category.id}]}
                      }else{
                          return {...prev};
                      }
                  });
                }}} itemId={category.id.toString()} label={category.name} />
        }

        return (
            <TreeItem ContentProps={{style: {textAlign: 'left'}}} itemId={category.id.toString()} label={category.name}>
                {categories.filter((i) => i.parentId === category.id).map((a) => {
                    return categoryParser(a);
                })}
            </TreeItem>
        )


    }, [categories]);

    return (

        <Box style={{display: 'flex', flexDirection: 'row',  width: '100%'}}>
            <Box sx={{ minHeight: 352, width: '28%', border: "1px solid #000"}} >
                <SimpleTreeView>
                    {categories.filter((catFilter) => catFilter.parentId === null).map((cat) => {
                        return categoryParser(cat);
                    })}
                </SimpleTreeView>
            </Box>
            <Box style={{width:'68%', minHeight: 352, border: "1px solid #000"}}>
                <SimpleTreeView>
                    {props.productData.categories.map((categorySelected) => {
                        const findCategory = categories.find((i) => i.id === categorySelected.id);

                        if(!findCategory){
                            return <></>
                        }
                        return (
                            <TreeItem onClick={() => {
                                props.setProductData((prev) => {
                                    return {...prev, categories: prev.categories.filter((i) => i.id !== findCategory.id)};
                                })
                            }} ContentProps={{style: {textAlign: 'left'}}} itemId={findCategory.id.toString()} label={findCategory.name} />
                        )
                    })}

                </SimpleTreeView>
            </Box>
        </Box>

    )

}
