import React, { Component, useState } from 'react'
export function Login(props: { setlogin: (b: boolean) => void }) {
    const [username, setUsername] = useState<string>('');
    const [psw, setPSW] = useState<string>('');
    const [loginError, setLoginError] = useState<boolean>(false);

    function login(): void {
        if (username === 'acventis' && psw === 'acventisPSW') {
            console.log('soihj')
            setLoginError(false)
            props.setlogin(true)
        } else {
            setLoginError(true)
        }
    }


    return (
        <div className='loginBody auth-wrapper '>

            <form style={{ backgroundColor: '#fff', padding: 25, borderRadius: 12 }} className='auth-inner'>
                <div style={{ width: '80%', marginLeft: '10%', display: 'flex', justifyContent: 'space-around', marginBottom: 25 }}>

                    <a target={'_blank'} href='https://www.acventis.de/'><img style={{ width: 100 }} src='logo3.png'></img></a>
                    <div style={{ fontSize: 50, color: 'rgb(36, 51, 59)', fontWeight: 'bold' }}>&</div>
                    <img style={{ width: 100 }} src='Download.png'></img>
                </div>
                <h3>Acventis Shop</h3>
                <div className="mb-3">
                    <label>Username</label>
                    <input
                        onChange={(text) => { setUsername(text.target.value) }}
                        className="form-control"
                        placeholder="Enter email"
                    />
                </div>
                <div className="mb-3">
                    <label >Passwort</label>
                    <input
                        onChange={(text) => { setPSW(text.target.value) }}
                        type="password"
                        className="form-control"
                        placeholder="Enter password"
                    />
                </div>
                {loginError &&
                    <div style={{ fontSize: 14, color: 'red', padding: 14 }}>Zugangsdaten falsch</div>
                }
                <div style={{}} className="d-grid">
                    <button onClick={() => login()} type={"button"} className="btn btn-primary">
                        Login
                    </button>
                </div>
            </form >
            <div style={{ position: 'absolute', left: 10, bottom: 10, color: '#fff', fontSize: 12, display: 'flex', alignItems: 'center' }}>

                <div style={{}}>Developed by KFM Digital Minds. <a target={'_blank'} style={{ color: 'rgb(44,168,86)' }} href="https://kfm-dm.de">www.kfm-dm.de</a></div>
            </div>

        </div>
    )
}