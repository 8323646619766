import { Button, Input, Switch, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Article, getPromoData } from "../repository/article";
import { category } from "../repository/category";
import { ReactComponent as SettingsIcon } from '../assets/settingsIcon.svg'
import { ReactComponent as PenIcon } from '../assets/penIcon.svg'
import { ReactComponent as TrashIcon } from '../assets/trashIcon.svg'
import axios from "axios";
import { environment } from "../environment";

type Props = {
    articles: Article[],
    close: () => void
    categories: category[]
}

export default function PromoPage(props: Props) {
    const [searchText, setSearchText] = useState('')
    const [openArticle, setOpenArticle] = useState<Article>()
    const [ek, setEk] = useState<string>('')
    const [vk, setVk] = useState<string>('')
    const [endDate, setEndDate] = useState<string>('')
    const [status, setStatus] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const [promoArticle, setPromoArticle] = useState<Article[]>([])

    useEffect(() => {
        loadPromoData()
    }, [])

    function loadPromoData() {
        getPromoData().then(res => {
            setEk('')
            setVk('')
            setEndDate('')
            setStatus(false)
            setPromoArticle(JSON.parse(JSON.stringify(res)))
            setLoading(false)
        })
    }

    function savePromoData(promoData: Article[]) {
        axios.put(environment.ownBackendURL + 'promo', { Promo: promoData }).then(res => {
            setLoading(true)
            loadPromoData()
        })
    }

    function getSearchResults(): Article[] {
        const searchResults = props.articles.map(article => {
            let categories = props.categories.filter(cat => {
                return article?.category?.includes(cat.id) || article?.subCategory?.includes(cat.id)
            })
            return { ...article, categories: categories.map(cat => cat.name).join(', ') }
        }).filter(article => {

            let search = searchText.toLowerCase()
            if(article){
                return article?.name?.toLowerCase()?.includes(search) ||
                    article?.categories?.toLowerCase()?.includes(search) ||
                    article?.supplierNumber?.toLowerCase()?.includes(search)
            }else{
                return false;
            }

        })
        return searchResults
    }

    function editArticle() {
        if (openArticle) {
            let articleToEdit = openArticle
            articleToEdit.endDate = endDate
            articleToEdit.status = status
            articleToEdit.newPriceEK = Number(ek)
            articleToEdit.pricePromo = vk !== '' ? Number(vk) : undefined
            let newAllArticles = promoArticle.filter(art => art.supplierNumber !== articleToEdit.supplierNumber)
            newAllArticles.push(articleToEdit)
            setPromoArticle(newAllArticles)
            savePromoData(newAllArticles)
            setOpenArticle(undefined)
        }
    }

    function isArticlePromo() {
        return promoArticle.map(art => art.supplierNumber).includes(`${openArticle?.supplierNumber}`)
    }

    function addArticle() {
        if (isArticlePromo()) {
            editArticle()
        } else {
            if (openArticle) {
                let articleToAdd = openArticle
                articleToAdd.endDate = endDate
                articleToAdd.status = status
                articleToAdd.newPriceEK = Number(ek)
                articleToAdd.pricePromo = vk !== '' ? Number(vk) : articleToAdd.pricePromo
                setPromoArticle(promoArticle.concat(articleToAdd))
                savePromoData(promoArticle.concat(articleToAdd))
                setOpenArticle(undefined)
            }
        }
    }

    function removeArticle(article: Article) {
        let newPromoData = promoArticle.filter(art => art.supplierNumber !== article.supplierNumber)
        setPromoArticle(newPromoData)
        savePromoData(newPromoData)
    }

    return (
        <div>

            <div style={{
                height: 160, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around',
            }}>
                <Button onClick={() => props.close()} color="success" style={{ position: 'relative', width: 80 }} variant="contained">Zurück</Button>
                <h1>Sonderpreisartikel</h1>
            </div>

            <table className="customers">
                <tr>
                    <th><SettingsIcon /></th>
                    <th>Name</th>
                    <th>Herstellernummer</th>
                    <th>Preis EK</th>
                    <th>Verkaufspreis</th>
                    <th>Kategorien</th>
                    <th>Status</th>
                    <th>Enddatum</th>
                    <th><SettingsIcon /></th>
                </tr>
                {loading &&
                    <tr key='delete'>
                        <div>Lädt...</div>
                    </tr>
                }
                {promoArticle.map((article) => {

                    return (
                        <tr key={article.incrID?.toString() + article.supplierNumber + 'promo'}>
                            <td>
                                <Button onClick={() => { setOpenArticle(article); setEk(`${article.newPriceEK}`); setVk(`${article.pricePromo ? article.pricePromo : ''}`); setEndDate(`${article.endDate}`); setStatus(article.status ? article.status : false) }} color="primary" style={{ position: 'relative', width: 80 }} variant="contained">
                                    <PenIcon />
                                </Button>
                            </td>
                            <td>{article.name.slice(0, 15)}..</td>
                            <td>{article.supplierNumber}</td>
                            <td>{article.newPriceEK?.toFixed(2)}</td>
                            <td>{article.pricePromo?.toFixed(2)}</td>
                            <td>{article.categories}</td>
                            <td>{`${article.status}`}</td>
                            <td>{article.endDate}</td>
                            <td>
                                <Button onClick={() => { removeArticle(article) }} color="error" style={{ position: 'relative', width: 80 }} variant="contained">
                                    <TrashIcon />
                                </Button>
                            </td>
                        </tr>
                    )
                })}
            </table>
            <div style={{ height: 2, backgroundColor: '#000', width: '95%', marginLeft: '2.5%', marginTop: 45, marginBottom: 45 }}></div>
            <div style={{
                height: 160, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around',
            }}>
                <h1>Wähle einen Artikel</h1>
                <TextField id="outlined-basic" label="Suche" variant="outlined" value={searchText} onChange={(event) => setSearchText(event.target.value)} />
            </div>
            <table className="customers">
                <tr>
                    <th><SettingsIcon /></th>
                    <th>Name</th>
                    <th>Herstellernummer</th>
                    <th>Preis EK</th>
                    <th>Verkaufspreis</th>
                    <th>Kategorien</th>
                </tr>

                {getSearchResults().slice(0, 15).map((article) => {

                    return (
                        <tr key={article.incrID?.toString() + article.supplierNumber}>
                            <td><Button onClick={() => { setOpenArticle(article); setEk(`${article.newPriceEK}`) }} color="primary" style={{ position: 'relative', width: 80 }} variant="contained">
                                <PenIcon />
                            </Button></td>
                            <td>{article.name}</td>
                            <td>{article.supplierNumber}</td>
                            <td>{article.newPriceEK?.toFixed(2)}</td>
                            <td>{article.price?.toFixed(2)}</td>
                            <td>{article.categories}</td>
                        </tr>
                    )
                })}
            </table>

            {
                openArticle !== undefined &&
                <div style={{ position: 'fixed', top: '45%', height: 300, width: '90%', left: '5%', borderRadius: 12, padding: 12, backgroundColor: '#fff', zIndex: 10000, display: 'flex', alignItems: 'flex-start', justifyContent: 'space-around', flexDirection: 'column', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                    <Button onClick={() => setOpenArticle(undefined)} color="error" style={{ position: 'relative', width: 80 }} variant="contained">Zurück</Button>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', width: '100%' }}>
                        <TextField id="outlined-basic" disabled={true} label="Herstellernummer" variant="outlined" value={openArticle.supplierNumber} />
                        <TextField id="outlined-basic" label="EK" variant="outlined" value={ek} onChange={(event) => setEk(event.target.value)} />
                        <TextField id="outlined-basic" label="Verkaufspreis " variant="outlined" value={vk} onChange={(event) => setVk(event.target.value)} />
                        <TextField id="outlined-basic" label="Enddatum (DD.MM.YYYY)" variant="outlined" value={endDate} onChange={(event) => setEndDate(event.target.value)} />
                        {status ? 'Aktiv' : 'Inaktiv'}
                        <Switch
                            checked={status}
                            onChange={(event) => setStatus(event.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </div>
                    <Button onClick={() => addArticle()} color="success" style={{ position: 'relative', width: 120 }} variant="contained">{isArticlePromo() ? 'Speichern' : 'Hinzufügen'}</Button>
                </div>
            }
        </div >
    )
}
