import { Button, Input, Switch, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import {Article, getPromoData, getSupplierData} from "../repository/article";
import {category, supplier} from "../repository/category";
import { ReactComponent as SettingsIcon } from '../assets/settingsIcon.svg'
import { ReactComponent as AddIcon } from '../assets/add.svg'
import { ReactComponent as TrashIcon } from '../assets/trashIcon.svg'
import axios from "axios";
import { environment } from "../environment";

type Props = {
    articles: Article[],
    close: () => void
    suppliers: supplier[]
    supplierList: supplier[];
    setSupplierList: (suppliers: supplier[]) => void;
}

export default function SuppliersPage(props: Props) {

    const [searchText, setSearchText] = useState('')
    const [loading, setLoading] = useState<boolean>(false)
    const [promoArticle, setPromoArticle] = useState<Article[]>([])

    function loadSupplierData() {
        getSupplierData().then(res => {
            props.setSupplierList(JSON.parse(JSON.stringify(res)));
            setLoading(false)
        })
    }

    function addSupplier(supData: supplier){
        supData.description = '';
        let supplierList = props.supplierList;
        supplierList.push(supData);
        saveSupplierData([...supplierList]);
    }


    function removeSupplier(supData: supplier){

       const indexOfItem = props.supplierList.findIndex((item) => item.id === supData.id);
        if(indexOfItem !== -1){
            let supplierList = props.supplierList;
            supplierList.splice(indexOfItem, 1);
            saveSupplierData(supplierList);
        }
    }

    function saveSupplierData(promoData: supplier[]) {
        axios.put(environment.ownBackendURL + 'savedSuppliers', { content: props.supplierList }).then(res => {
            setLoading(true)
            loadSupplierData()
        })
    }

    function getSearchResults(): supplier[] {
        const searchResults = props.suppliers.filter(supplier => {

            if(props.supplierList.map((i) => i.id).includes(supplier.id)){
                return false;
            }

            let search = searchText.toLowerCase()
            return supplier.name.toLowerCase().includes(search) ||
                supplier.metaKeywords?.toLowerCase().includes(search) ||
                supplier.metaTitle?.toLowerCase().includes(search) ||
                supplier.metaDescription?.toLowerCase().includes(search)
        })
        return searchResults;
    }


    return (
        <div>

            <div style={{
                height: 160, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around',
            }}>
                <Button onClick={() => props.close()} color="success" style={{ position: 'relative', width: 80 }} variant="contained">Zurück</Button>
                <h1>Filtered Suppliers</h1>
            </div>

            <table className="customers">
                <tr>
                    <th>Name</th>
                    <th>Link</th>
                    <th>Keywords</th>
                    <th><SettingsIcon /></th>
                </tr>
                {loading &&
                    <tr key='delete'>
                        <div>Lädt...</div>
                    </tr>
                }
                {props.supplierList.map((supplier) => {

                    return (
                        <tr key={supplier.id?.toString() + "--p"}>

                            <td>{supplier.name}</td>
                            <td>{supplier.link}</td>
                            <td>{supplier.metaKeywords}</td>
                            <td><Button onClick={() => removeSupplier(supplier)} color="primary" style={{ position: 'relative', width: 80 }} variant="contained">
                                <TrashIcon />
                            </Button></td>
                        </tr>
                    )
                })}
            </table>
            <div style={{ height: 2, backgroundColor: '#000', width: '95%', marginLeft: '2.5%', marginTop: 45, marginBottom: 45 }}></div>
            <div style={{
                height: 160, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around',
            }}>
                <h1>All Suppliers</h1>
                <TextField id="outlined-basic" label="Suche" variant="outlined" value={searchText} onChange={(event) => setSearchText(event.target.value)} />
            </div>
            <table className="customers">
                <tr>

                    <th>Name</th>
                    <th>Link</th>
                    <th>Keywords</th>
                    <th><SettingsIcon /></th>
                </tr>

                {getSearchResults().slice(0, 15).map((supplier) => {

                    return (
                        <tr key={supplier.id?.toString()}>

                            <td>{supplier.name}</td>
                            <td>{supplier.link}</td>
                            <td>{supplier.metaKeywords}</td>
                            <td><Button onClick={() => addSupplier(supplier)} color="primary" style={{ position: 'relative', width: 80 }} variant="contained">
                                <AddIcon />
                            </Button></td>
                        </tr>
                    )
                })}
            </table>
        </div >
    )
}
