import { Button } from "@mui/material";
import LeftIcon from "@mui/icons-material/ArrowLeft";
import CategorySelectBox from "./Components/CategorySelectBox";
import React, { useEffect, useState } from "react";
import { Article } from "./repository/article";
import { category } from "./repository/category";
import ProductSelectBox from "./Components/ProductSelectBox";

export default function (props: { categories: category[], setNotPresentView: (status: boolean) => void; missingInCSV: Article[], finish: () => void }) {

    const [filteredArticles, setFilteredArticles] = useState<Article[]>([]);
    const [requiredCategoryIDS, setRequiredCategoryIDS] = useState<number[]>([]);
    const [categoryList, setCategoryList] = useState<category[]>([]);

    useEffect(() => {
        const buildStructure: category[] = [];
        if (requiredCategoryIDS.length !== 0) {
            requiredCategoryIDS.forEach((catID) => {
                const findCategory = props.categories.find((cat) => cat.id === catID);
                if (findCategory !== undefined && buildStructure.findIndex((i) => i.id === catID) === -1) {
                    buildStructure.push(findCategory);
                }
            })
        }
        //console.log(buildStructure);
        setCategoryList(buildStructure);

    }, [props.categories, requiredCategoryIDS]);

    useEffect(() => {

        let filteredProducts = props.missingInCSV.filter((item) => {
            return !item.category.includes(100) && !item.subCategory.includes(100) && (item.attr8 === "" || item.attr8 === null)
        });
        filteredProducts = filteredProducts.filter((element, index) => {
            return filteredProducts.findIndex((item) => item.incrID === element.incrID) === index;
        });
        setFilteredArticles(filteredProducts);

        let requiredID: number[] = [];

        filteredProducts.forEach((fil) => {
            requiredID = [...requiredID, ...fil.category, ...fil.subCategory];
        });

        requiredID = Array.from(new Set(requiredID));
        //console.log("ID list", requiredID, "Filtered :", filteredProducts);
        setRequiredCategoryIDS([...requiredID]);


    }, [props.missingInCSV, props.categories]);

    return (
        < div style={{ marginTop: 80, height: '100vh' }}>
            <Button onClick={() => props.setNotPresentView(false)} style={{ position: 'absolute', left: 12, top: 12 }} variant="contained">
                <LeftIcon style={{ color: '#fff' }} />
                Zurück
            </Button>

            <ProductSelectBox saveMargens={() => { props.finish() }} categories={categoryList} products={filteredArticles} onChange={() => { }} multiple={true} />
        </div>
    )

}
